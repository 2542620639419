import React from 'react';
import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Greeting } from '../../components/greeting';
import { DashboardMarketingCard } from '../../components/dashboard-marketing-card';
import { useTranslation } from 'react-i18next';
import { Contract } from '../../components/contracts';
import { UnpaidBalance } from '../../components/unpaid-balance';
import { LoyaltyNotification } from '../../components/loyalty-notification';
import { RenewalNotification } from '../../components/renewal-notification';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    <RenewalNotification />
                    <LoyaltyNotification />
                    <UnpaidBalance />
                    <Contract />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <DashboardMarketingCard content={'warranty'} />
                    <DashboardMarketingCard content={'marketing'} />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
