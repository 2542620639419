import axios from 'axios';

import { currentBrand, integrationApiBaseUrlLocal } from '../config';

export const CpDataApi = axios.create({
    baseURL: integrationApiBaseUrlLocal,
});

export const CpContentApi = axios.create({
    baseURL: '/contents',
    headers: {
        'X-CP-Brand': currentBrand,
    },
});
