export function getFinanceSimulationEndpoint(): string {
    return '/finance-simulation';
}
export function getCalculationEndpoint(): string {
    return `${getFinanceSimulationEndpoint()}/calculation`;
}

export function getRateChargeEndpoint(): string {
    return `${getFinanceSimulationEndpoint()}/rate-charge`;
}

export function getRiskClassEndpoint(): string {
    return `${getFinanceSimulationEndpoint()}/risk-class`;
}
export function getCreateRequestEndpoint(): string {
    return `${getFinanceSimulationEndpoint()}/request`;
}
