import { CategoryOpenRequestEnum } from '../types';

export function getCreateCustomerRequestEndpoint(): string {
    return '/open-request';
}

export function getCatalogOpenRequestEndpoint(): string {
    return '/catalog/open-request';
}

export function getCatalogStateEndpoint(): string {
    return '/catalog/states';
}

export function postOpenRequestSalesforceEndpoint(category: CategoryOpenRequestEnum): string {
    return `/open-request/salesforce/${category}`;
}
