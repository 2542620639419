import { CatalogStatesResponse, getCatalogStateEndpoint } from '@cp-mx/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../../../cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<CatalogStatesResponse, DefaultBusinessMarketApiErrorCode>({
    dataName: 'stateCatalog',
    fetchCallback() {
        return CpDataApi.get<CatalogStatesResponse>(getCatalogStateEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchStateSelection = fetchData;
