import { NotificationsCenter, getNotificationsByCustomerEndpoint } from '@cp-mx/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    NotificationsCenter[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'notificationCenter',
    fetchCallback() {
        return CpDataApi.get<NotificationsCenter[]>(getNotificationsByCustomerEndpoint()).then(
            response => response.data,
        );
    },
});

export default reducer;
export const fetchNotificationsAll = fetchData;
export const updateNotification = updateData;
