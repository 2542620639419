import { VehicleDetails, getVehicleDetailsEndpoint } from '@cp-mx/common';
import { DefaultMarketApiErrors } from '@cp-shared-9/common-utilities';
import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
} from '@cp-shared-9/frontend-integration';
import { useContractEspecific } from '../../useContracts';
import { useMemo } from 'react';
import { fetchVehicleDetails } from './VehicleDetailsSlice';
import { VehicleDetailsDataSelector } from './VehicleDetailsContentSelector';

export function useVehicleDetails(contractId: string): AbstractDataState<VehicleDetails, DefaultMarketApiErrors> {
    const contractState = useContractEspecific(contractId);
    const vehicleDetailsState = useGetContractBasedApiData(
        contractId,
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        getVehicleDetailsEndpoint(contractState.data?.contractNumber ? contractState.data?.contractNumber : ''),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || vehicleDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && vehicleDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? vehicleDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: vehicleDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? vehicleDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, vehicleDetailsState],
    );
}
