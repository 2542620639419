import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@vwfs-bronson/bronson-react';
import { Loyalty, NotificationsCenter, getCertificateEndpoint } from '@cp-mx/common';
import { Notification, NotificationStatus, Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import { useNotification } from '../useNotification';
import { useNotificationCenter } from '../../notification-center/useNotificationCenter';

export const LoyaltyNotificationUi: React.FC<{ loyalties?: Loyalty[] }> = ({ loyalties }) => {
    const { t } = useTranslation('loyalty-notification');
    const [isLoading, setIsLoading] = useState<number[]>([]);
    const [notificationError, setNotificationError] = useState<number[]>([]);
    const { notificationState, setNotification } = useNotification();
    const { data: notifications } = useNotificationCenter();
    let filteredNotificationContracts: NotificationsCenter[] = [];
    const { onAction: onApiError } = useAnalyticsActionTracker('onRequestCertificateSubmitAPIError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestCertificateSuccess');

    const handleOnClose = (notification: Loyalty) => {
        setNotification(notification.loyaltyProgramId);
    };

    const getFileCertificate = async (loyalty: Loyalty) => {
        setIsLoading(state => {
            return [...state, loyalty.loyaltyProgramId];
        });
        CpDataApi.get(getCertificateEndpoint(`${loyalty.loyaltyProgramId}?pdf=true`))
            .then(response => {
                const url = response.data.signedUrl;
                window.open(url);
                onSuccess();
                setIsLoading(state => {
                    return state.filter(e => e !== loyalty.loyaltyProgramId);
                });
            })
            .catch(() => {
                onApiError();
                setIsLoading(state => {
                    return state.filter(e => e !== loyalty.loyaltyProgramId);
                });
                setNotificationError(state => {
                    return [...state, loyalty.loyaltyProgramId];
                });
            });
    };

    const displayLoadingSpinner = (loyalty: Loyalty) => {
        const linkText = (
            <>
                {t('warning.moreInfo')}
                <Button
                    className="u-font-size-fs0"
                    style={{ textTransform: 'lowercase' }}
                    link
                    onClick={() => getFileCertificate(loyalty)}
                >
                    {t('warning.link')}
                </Button>
                {t('warning.certificate')}
            </>
        );

        const spinner = <Spinner small center />;

        if (isLoading.includes(loyalty.loyaltyProgramId)) {
            return spinner;
        } else {
            return linkText;
        }
    };

    if (!loyalties?.length) {
        return null;
    }

    const getLoyaltyData = (idContract: string) => {
        return loyalties.filter(contract => contract.contractNumber === idContract)[0];
    };

    if (notifications && notifications.length) {
        filteredNotificationContracts = notifications.filter(item1 =>
            loyalties.some(item2 => item1.contractNumber === item2.contractNumber && item1['loyalty']),
        );
    }

    return (
        <>
            {filteredNotificationContracts.map(loyaltyNotification => {
                const loyaltyContract = loyaltyNotification.loyalty;
                if (loyaltyContract && loyaltyContract.dashboardNotificationBySessionsCount) {
                    const loyalty = getLoyaltyData(loyaltyNotification.contractNumber);
                    if (!notificationError.includes(loyalty.loyaltyProgramId)) {
                        if (!loyalty.status) {
                            return (
                                <Notification
                                    showCloseButton
                                    visible={!notificationState.includes(loyalty.loyaltyProgramId)}
                                    onCloseClick={() => handleOnClose(loyalty)}
                                    key={loyalty.loyaltyProgramId}
                                    headline={t('warning.headline')}
                                    testId={`testIdWarning${loyalty.loyaltyProgramId}`}
                                >
                                    {t('warning.loyalty')}
                                    {t('warning.benefit')}
                                    <br />
                                    <br />
                                    {displayLoadingSpinner(loyalty)}
                                </Notification>
                            );
                        } else {
                            return (
                                <Notification
                                    showCloseButton
                                    visible={!notificationState.includes(loyalty.loyaltyProgramId)}
                                    onCloseClick={() => handleOnClose(loyalty)}
                                    key={loyalty.loyaltyProgramId}
                                    status={NotificationStatus.success}
                                    headline={t('success.headline')}
                                    text={t('success.body')}
                                    testId={`testIdSuccess${loyalty.loyaltyProgramId}`}
                                />
                            );
                        }
                    } else {
                        return (
                            <Notification
                                showCloseButton
                                visible={notificationError.includes(loyalty.loyaltyProgramId)}
                                onCloseClick={() =>
                                    setNotificationError(state => {
                                        return state.filter(e => e !== loyalty.loyaltyProgramId);
                                    })
                                }
                                key={`notification-error-${loyalty.loyaltyProgramId}`}
                                status={NotificationStatus.error}
                                headline={t('error.headline')}
                                testId={`testIdError${loyalty.loyaltyProgramId}`}
                            >
                                {t('error.body')}
                            </Notification>
                        );
                    }
                } else {
                    return null;
                }
            })}
        </>
    );
};
