import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-unresolved
import 'localization/i18n';
import store from './store';
import { MX_TIMEZONE } from '@cp-mx/common';

const renderApp = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const App = require('./App').default;
    render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

moment.tz.setDefault(MX_TIMEZONE);
renderApp();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', renderApp);
}
