import { IconFooter, getIconFooterEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from '../../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<IconFooter>({
    contentName: 'iconFooter',
    contentEndpoint: getIconFooterEndpoint,
});

export default reducer;
export const fetchIconFooter = fetchContent;
