import React, { useState } from 'react';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { RegistrationError, RegistrationResult } from '@cp-mx/common';
import {
    Notification,
    NotificationStatus,
    ScrollToTopOnNavigation,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-9/frontend-ui';
import { PersonIdentificationForm } from './person-identification-form';
import { Layout, Tabs } from '@vwfs-bronson/bronson-react';
import { tabs } from './availableTabs';
import { useTranslation } from 'react-i18next';
import { BusinessIdentificationForm } from './business-identification-form';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { BusinessFormProps, CustomerType, PersonFormProps } from './types';

type IdentificationProps = {
    onSubmitSuccess: (
        result: RegistrationResult,
        customerType: CustomerType,
        registrationForm: PersonFormProps | BusinessFormProps,
    ) => void;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError>;
    errorMessage?: string;
    lastFourDigitsOfPhoneNumber?: string;
    customerId?: string;
};

export const Identification: React.FC<IdentificationProps> = ({ onSubmitSuccess }) => {
    const { t } = useTranslation('registration');
    const { onAction: onRegistrationFailureAction } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');
    const { onAction: onRegistrationSuccessAction } = useAnalyticsActionTracker('onConfirmIdentiyAuthSuccess');
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
        errorMessage: '',
        lastFourDigitsOfPhoneNumber: '',
        customerId: '',
    });

    const onSubmitClick = () => {
        setRegistrationRequest({ ...registrationRequest, isLoading: true });
    };

    const onSuccess = (customerType: CustomerType) => (
        result: RegistrationResult,
        registrationForm: BusinessFormProps | PersonFormProps,
    ) => {
        onRegistrationSuccessAction();
        const { idCustomer } = result;
        setRegistrationRequest({
            isLoading: false,
            errorCode: undefined,
            errorMessage: '',
            customerId: idCustomer,
        });
        onSubmitSuccess(result, customerType, registrationForm);
    };

    const onError = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => {
        onRegistrationFailureAction();
        setRegistrationRequest({
            isLoading: false,
            errorCode,
            errorMessage,
            lastFourDigitsOfPhoneNumber: '',
            customerId: '',
        });
    };

    const getErrorNotificationLabel = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>): string => {
        return t(`registration-form.error.${errorCode}`);
    };

    const isRegistrationErrorCode = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>) => {
        return errorCode in RegistrationError;
    };

    const { isLoading, errorCode } = registrationRequest;

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                <h1>{t('registration-form.head.title')}</h1>
                <Notification status={NotificationStatus.info} className={'u-text-left'}>
                    <>
                        <span>{t('registration-form.head.info-identification')}</span>
                        <br />
                        <span>{t('registration-form.head.info-legal-identification')}</span>
                    </>
                </Notification>
                {errorCode && !isRegistrationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <NoConnectionNotification />
                    </ScrollToTopOnNavigation>
                )}
                {errorCode && isRegistrationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <Notification
                            className="u-mt"
                            testId={'error-notification-identification-form'}
                            status={NotificationStatus.error}
                            text={getErrorNotificationLabel(errorCode)}
                        />
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <div className="u-text-center">
                    <h5>{t('registration-form.head.subtitle')}</h5>
                </div>
                <Tabs defaultSelected={tabs.PRIVATE} testId={'registrationTabs'}>
                    <Tabs.Header>
                        <Tabs.HeaderItem tabId={tabs.PRIVATE} className={`cp-tab-button-${tabs.PRIVATE}`}>
                            {t('registration-form.head.tabs.person.label')}
                        </Tabs.HeaderItem>
                        <Tabs.HeaderItem tabId={tabs.BUSINESS} className={`cp-tab-button-${tabs.BUSINESS}`}>
                            {t('registration-form.head.tabs.business.label')}
                        </Tabs.HeaderItem>
                    </Tabs.Header>
                    <Tabs.Content>
                        <Tabs.ContentItem tabId={tabs.PRIVATE} testId={`tab-content-${tabs.PRIVATE}`}>
                            <PersonIdentificationForm
                                onSubmitClick={onSubmitClick}
                                onSuccess={onSuccess(CustomerType.PRIVATE)}
                                onError={onError}
                            />
                        </Tabs.ContentItem>
                        <Tabs.ContentItem tabId={tabs.BUSINESS} testId={`tab-content-${tabs.BUSINESS}`}>
                            <BusinessIdentificationForm
                                onSubmitClick={onSubmitClick}
                                onSuccess={onSuccess(CustomerType.BUSINESS)}
                                onError={onError}
                            />
                        </Tabs.ContentItem>
                    </Tabs.Content>
                </Tabs>
            </Layout.Item>
        </>
    );
};
