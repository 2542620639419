import { SimplePage, getCookiePolicyEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePage>({
    contentName: 'cookiePolicy',
    contentEndpoint: getCookiePolicyEndpoint,
});

export default reducer;
export const fetchCookiePolicy = fetchContent;
