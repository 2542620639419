import { CatalogOptions } from '@cp-mx/common';
import { Checkbox, ErrorMessage, Layout } from '@vwfs-bronson/bronson-react';
import { FieldArray, FormikProps, useField, useFormikContext } from 'formik';
import React from 'react';
import { RequestInitialValues, RequestInitialValuesEnum } from '../initialValues';

export const FormikCheckboxGroup: React.FC<{
    name: RequestInitialValuesEnum;
    label: string;
    options: Array<CatalogOptions>;
    spacing?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: Function;
    border?: boolean;
}> = ({ border, name, options, label, spacing }) => {
    const { values }: FormikProps<RequestInitialValues> = useFormikContext();
    const [field, meta] = useField(name);

    return options.length ? (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <>
                    <label className="c-form-field__label">{label}</label>
                    <div className={`${border ? 'u-border u-pt' : ''}`}>
                        {options.map((elem, index) => {
                            return (
                                <Layout.Item
                                    key={index}
                                    className={` ${spacing ? spacing : 'u-1/3 u-1/1@m u-1/1@s 1/1@xs'} u-mb`}
                                >
                                    <Checkbox
                                        key={elem.id}
                                        name={name}
                                        type="checkbox"
                                        value={elem.description}
                                        checked={values[name].includes(elem.description)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                arrayHelpers.push(elem.description);
                                            } else {
                                                const idx = values[name].indexOf(elem.description);
                                                arrayHelpers.remove(idx);
                                            }
                                        }}
                                    >
                                        {elem.description}
                                    </Checkbox>
                                </Layout.Item>
                            );
                        })}
                    </div>
                    {!field.value.length && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
                </>
            )}
        />
    ) : null;
};
