import React from 'react';
import { withLoadingHandler } from '../integration-wrapper';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { useMyContact } from './useMyContact';
import { useCustomer } from '../../auth/customer';

const MyProfileWithHandlers = withLoadingHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data, isLoading } = useMyProfile();
    const { data: myContact } = useMyContact();
    const { data: customerId } = useCustomer();

    useAnalyticsPageViewTracker('profile', !!data);

    return (
        <div>
            <MyProfileWithHandlers
                isLoading={isLoading}
                myProfileData={data}
                myContact={myContact}
                customerId={customerId}
            />
        </div>
    );
};
