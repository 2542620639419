import React from 'react';
import { FinanceSimulationCalculatorUi } from './ui';
import { useRenewalContract } from '../renewal-notification/useRenewalOption';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { useBrandContent, useContract, useModelContent } from '../contracts';
import { useLoyalty } from '../loyalty-notification/useLoyaltyNotification';
import { useMyProfile } from '../my-profile';
import {
    useFinanceSimulationModelContent,
    useRateChargePFA,
    useRateChargePFP,
    useRateChargePM,
    useRiskRate,
} from './useFinanceSimualtion';
import { useCustomer } from '../../auth/customer';
import { withNoConnectionHandler } from '../integration-wrapper';

export const FinanceSimulationCalculator: React.FC = () => {
    const FinanceSimulationCalculatorWithHandlers = withNoConnectionHandler(FinanceSimulationCalculatorUi);

    useAnalyticsPageViewTracker('digitalRenewalPage', !!FinanceSimulationCalculator);
    const { renewalContractState } = useRenewalContract();
    const { data: contracts } = useContract();
    const { data: profile } = useMyProfile();
    const { cmsContent: modelCatalog } = useModelContent();
    const { cmsContent: brandCatalog } = useBrandContent();
    const { cmsContent: financeSimulationModelContent } = useFinanceSimulationModelContent();
    const { data: loyalty } = useLoyalty();
    const { data: customerId } = useCustomer();
    let contractInformation = {
        contractNumber: '',
        contractLabel: '',
        paymentDay: '',
        paymentInstallment: 0,
        contractStartDate: '',
        contractEndDate: '',
        applicationReceiptDate: '',
        startOfBilling: '',
        product: {
            type: '',
            name: '',
            group: '',
        },
        vehicle: {
            brand: '',
            model: '',
            year: '',
            product: '',
        },
        balloonPayment: 0,
        financedAmount: 0,
    };
    let model = '';
    if (contracts?.length) {
        contracts.forEach(contract => {
            if (contract.contractNumber === renewalContractState.contractNumber) {
                contractInformation = contract;
            }
        });
    }
    const { data: rateChargePFP, isLoading: isLoadingPFP, loadingError: loadingErrorPFP } = useRateChargePFP(
        renewalContractState.contractNumber,
    );
    const { data: rateChargePFA, isLoading: isLoadingPFA, loadingError: loadingErrorPFA } = useRateChargePFA(
        renewalContractState.contractNumber,
    );
    const { data: rateChargePM, isLoading: isLoadingPM, loadingError: loadingErrorPM } = useRateChargePM(
        renewalContractState.contractNumber,
    );
    if (financeSimulationModelContent) {
        if (contractInformation.vehicle.brand === 'CUPRA') {
            model = `C${contractInformation.vehicle.model}`.toUpperCase();
            model = financeSimulationModelContent[model];
        } else {
            model = financeSimulationModelContent[contractInformation.vehicle.model];
        }
        if (contractInformation.vehicle.product.includes('Q3 SB')) {
            model = financeSimulationModelContent['Q3SB'];
        }
        if (contractInformation.vehicle.product.includes('LEON ST ')) {
            model = financeSimulationModelContent['LEONST'];
        }
        if (contractInformation.vehicle.product.includes('Q8 SPORTBACK E-TRON')) {
            model = financeSimulationModelContent['Q8ET'];
        }
    }
    const { data: riskRate, isLoading: isLoadingRiskRate, loadingError: loadingErrorRiskRate } = useRiskRate(
        contractInformation,
        model,
    );
    return (
        <FinanceSimulationCalculatorWithHandlers
            isLoadingPage={isLoadingPFA || isLoadingPFP || isLoadingPM || isLoadingRiskRate}
            rateChargePM={rateChargePM}
            rateChargePFP={rateChargePFP}
            rateChargePFA={rateChargePFA}
            riskRate={riskRate}
            contract={contractInformation}
            profile={profile}
            modelCatalog={modelCatalog}
            brandCatalog={brandCatalog}
            loyalty={loyalty}
            customerId={customerId}
            hasError={!!loadingErrorPFP || !!loadingErrorPFA || !!loadingErrorPM || !!loadingErrorRiskRate}
        />
    );
};
