import { ContractDetails, getContractDetailsEndpoint } from '@cp-mx/common';

import { createCmsContentSlice } from '../../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ContractDetails>({
    contentName: 'contractDetails',
    contentEndpoint: getContractDetailsEndpoint,
});

export default reducer;
export const fetchContractDetails = fetchContent;
