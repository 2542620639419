import { CatalogOpenRequestResponse, getCatalogOpenRequestEndpoint } from '@cp-mx/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../../../cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<CatalogOpenRequestResponse[], DefaultBusinessMarketApiErrorCode>(
    {
        dataName: 'categoryCatalog',
        fetchCallback() {
            return CpDataApi.get<CatalogOpenRequestResponse[]>(getCatalogOpenRequestEndpoint()).then(
                response => response.data,
            );
        },
    },
);

export default reducer;
export const fetchCategorySelection = fetchData;
