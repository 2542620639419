import {
    RenewalOption,
    RenewalPremiumCreditInfoModalStep,
    getRenewalCreditCatalogEndpoint,
    getRenewalLeasingCatalogEndpoint,
    getRenewalPremiumCreditCatalogEndpoint,
    getRenewalPremiumCreditInfoModalCatalogEndpoint,
} from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer: credit, fetchContent: fetchCredit } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalCreditOptions',
    contentEndpoint: getRenewalCreditCatalogEndpoint,
});
const { reducer: leasing, fetchContent: fetchLeasing } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalLeasingOptions',
    contentEndpoint: getRenewalLeasingCatalogEndpoint,
});
const { reducer: premiumCredit, fetchContent: fetchPremiumCredit } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalPremiumCreditOptions',
    contentEndpoint: getRenewalPremiumCreditCatalogEndpoint,
});
const { reducer: premiumCreditInfoModalSteps, fetchContent: fetchPremiumCreditInfoModal } = createCmsContentSlice<
    RenewalPremiumCreditInfoModalStep[]
>({
    contentName: 'renewalPremiumCreditInfoModalSteps',
    contentEndpoint: getRenewalPremiumCreditInfoModalCatalogEndpoint,
});

export const creditReducer = credit;
export const fetchRenewalCreditOptions = fetchCredit;

export const leasingReducer = leasing;
export const fetchRenewalLeasingOptions = fetchLeasing;

export const premiumCreditReducer = premiumCredit;
export const fetchRenewalPremiumCreditOptions = fetchPremiumCredit;

export const premiumCreditInfoModalStepsReducer = premiumCreditInfoModalSteps;
export const fetchPremiumCreditInfoModalSteps = fetchPremiumCreditInfoModal;
