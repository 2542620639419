import React, { useState, useEffect } from 'react';
import { CustomSelectElement } from './CustomSelectElement';
import { FormFieldLabel } from '@vwfs-bronson/bronson-react';

export type CustomSelectProps = {
    label: string;
    required?: boolean;
    dropdownElements: CustomSelectElement[];
    selectedKey?: string;
    onChange: (value: string) => void;
};

export const CustomSelectUI: React.FC<CustomSelectProps> = ({
    label,
    required = false,
    dropdownElements,
    selectedKey,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const init = dropdownElements[0];
    const [selectedItem, setSelectedItem] = useState<CustomSelectElement>(init);

    useEffect(() => {
        setSelectedItem(dropdownElements.find((item: CustomSelectElement) => item.optionKey === selectedKey) || init);
    }, [selectedKey, dropdownElements]);

    const handleClick = (selectedItem: CustomSelectElement) => {
        setSelectedItem(selectedItem);
        onChange(selectedItem.returnValue);
        setIsOpen(false);
    };
    return (
        <>
            <FormFieldLabel>{`${label} ${required ? '*' : ''}`}</FormFieldLabel>
            <div
                tabIndex={0}
                className="c-combobox__wrapper  js-combobox"
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
            >
                <div
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    aria-owns="combo-single-listbox"
                    className="c-combobox"
                >
                    <div className="c-combobox__value-presentation  js-combobox-value-presentation">
                        <span id="combo-single-values" className="c-combobox__value-presentation__text">
                            {selectedItem?.displayValue}
                        </span>
                    </div>

                    <div className="c-combobox__listbox-container">
                        {dropdownElements.map((item: CustomSelectElement) => (
                            <div
                                id={item.optionKey}
                                key={item.optionKey}
                                className={`c-combobox__item `}
                                aria-selected={selectedItem?.optionKey === item.optionKey ? 'true' : 'false'}
                                aria-disabled="false"
                                onClick={() => handleClick(item)}
                            >
                                <div className="c-combobox__item__text">{item.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
