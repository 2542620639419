import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { NotificationCenter } from '@cp-mx/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { fetchNotificationsCenterAll } from './NotificationCenterComponentSlice';
import { useNotificationsComponentAllSelector } from './NotificationCenterComponentSelector';

export function useNotificationCenterComponent(): AbstractDataState<
    NotificationCenter[],
    DefaultBusinessMarketApiErrorCode
> {
    return useGetSimpleApiData(fetchNotificationsCenterAll, useNotificationsComponentAllSelector);
}
