import React from 'react';
import { useTranslation } from 'react-i18next';
import { Greeting as GreetingShared } from '@cp-shared-9/frontend-ui';
import { Greeting } from '@cp-mx/common';

export const GreetingUi: React.FC<{ greeting?: Greeting }> = ({ greeting }) => {
    const { t } = useTranslation('greeting');

    const greetingText = greeting?.name ? `${t('headline')}, ${greeting.name}` : t('headline');

    return <GreetingShared fullGreetingText={greetingText} />;
};
