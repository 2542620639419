import React from 'react';
import { ContentSection, Layout, Logo, PageWrap } from '@vwfs-bronson/bronson-react';
import { RegistrationResult } from '@cp-mx/common';
import { Identification } from './identification';
import { BusinessFormProps, CustomerType, PersonFormProps } from './identification/types';
import { dashboardPagePath } from '../../navigation/paths';
import { useHistory } from 'react-router-dom';

export const RegistrationUi: React.FC = () => {
    const history = useHistory();
    const onSubmitSuccess = (
        registrationResult: RegistrationResult,
        customerType: CustomerType,
        customerFormData: PersonFormProps | BusinessFormProps,
    ): void => {
        if (registrationResult?.idCustomer && customerType && customerFormData) {
            history.push(dashboardPagePath());
        }
    };

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout className={'u-mt-large'}>
                    <Layout.Item default={'1/1'}>
                        <Logo className={'u-block-center'} />
                    </Layout.Item>
                    <Identification onSubmitSuccess={onSubmitSuccess} />
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};
