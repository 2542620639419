import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { LandingPageMarketingCardsUi } from './ui';
import { useLandingPageMarketingCards } from './useLandingPageMarketingCards';

const LandingPageMarketingCardsWithHandlers = withLoadingAndNoConnectionHandler(LandingPageMarketingCardsUi);

export const LandingPageMarketingCards: React.FC = () => {
    const { cmsContent: landingPageMarketingCards, isLoading, loadingError } = useLandingPageMarketingCards();

    return (
        <LandingPageMarketingCardsWithHandlers
            isLoading={isLoading}
            landingPageMarketingCards={landingPageMarketingCards}
            hasError={!!loadingError}
        />
    );
};
