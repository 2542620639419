import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { authorizationReducer } from '../auth';
import iconFooterReducer from '../components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from '../components/footer/legal-footer/LegalFooterSlice';
import greetingReducer from '../components/greeting/GreetingSlice';
import faqReducer from '../components/faq/FaqSlice';
import faqTeaserReducer from '../components/faq-teaser/FaqTeaserSlice';
import landingPageMarketingCardsReducer from '../components/landing-page-marketing-cards/LandingPageMarketingCardsSlice';
import dashboardMarketingCardReducer from '../components/dashboard-marketing-card/DashboardMarketingCardSlice';
import landingPageTeaserReducer from '../components/landing-page-teaser/LandingPageTeaserSlice';
import privacyPolicyReducer from '../components/privacy-policy/PrivacyPolicySlice';
import cookiePolicyReducer from '../components/cookie-policy/CookiePolicySlice';
import legalNoticeReducer from '../components/legal-notice/LegalNoticeSlice';
import myProfileReducer from '../components/my-profile/MyProfileSlice';
import contractsReducer from '../components/contracts/ContractSlice';
import brandsReducer from '../components/contracts/ContractBrandSlice';
import modelReducer from '../components/contracts/ContractModelSlice';
import productReducer from '../components/contracts/ContractProductSlice';
import unpaidBalanceReducer from '../components/unpaid-balance/UnpaidBalanceSlice';
import contractDetailsReducer from '../components/contracts/contract-details/ContractDetailsSlice';
import financialDetailsReducer from '../components/contracts/contract-details/financial-details/FinancialDetailsSlice';
import productNamesReducer from '../components/contracts/contract-details/financial-details/ProductNameSlice';
import vehicleDetailsReducer from '../components/contracts/contract-details/vehicle-details/VehicleDetailsSlice';
import unauthorizedReducer from '../components/unauthorized/UnauthorizedSlice';
import categoryCatalogReducer from '../components/request/form-view/category-selection/CategorySelectionSlice';
import loyaltyReducer from '../components/loyalty-notification/LoyaltyNotificationSlice';
import { notificationReducer } from '../components/loyalty-notification/NotificationSlice';
import renewalContractReducer from '../components/renewal-notification/RenewalContractSlice';
import { renewalOptionContractReducer } from '../components/renewal-notification/RenewalOptionSlice';
import notificationCenterReducer from '../components/notification-center/NotificationCenterSlice';
import notificationCenterComponentReducer from '../components/notification-center/NotificationCenterComponentSlice';
import stateCatalogReducer from '../components/request/form-view/state-selection/StateSlice';
import {
    creditReducer,
    leasingReducer,
    premiumCreditInfoModalStepsReducer,
    premiumCreditReducer,
} from '../components/renewal-options/RenewalOptionsSlice';
import customerReducer from '../auth/customer/CustomerSlice';
import { notificationRenewalCloseReducer } from '../components/renewal-notification/NotificationCloseSlice';
import myContactReducer from '../components/my-profile/MyContactSlice';
import myMktPreferencesReducer from '../components/my-profile/MyMktPreferencesSlice';
import RateChargePFPReducer from '../components/finance-simulation-calculator/RateChargePFPSlice';
import RateChargePFAReducer from '../components/finance-simulation-calculator/RateChargePFASlice';
import RateChargePMReducer from '../components/finance-simulation-calculator/RateChargePMSlice';
import RiskRateReducer from '../components/finance-simulation-calculator/RiskRateSlice';
import financeSimulationModelReducer from '../components/finance-simulation-calculator/FinanceSimulationModelSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    authorization: authorizationReducer,
    myProfile: myProfileReducer,
    greeting: greetingReducer,
    unpaidBalance: unpaidBalanceReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        faq: faqReducer,
        faqTeaser: faqTeaserReducer,
        landingPageTeaser: landingPageTeaserReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        privacyPolicy: privacyPolicyReducer,
        cookiePolicy: cookiePolicyReducer,
        legalNotice: legalNoticeReducer,
        contracts: contractsReducer,
        brands: brandsReducer,
        models: modelReducer,
        products: productReducer,
        contractDetails: contractDetailsReducer,
        productNames: productNamesReducer,
        unauthorized: unauthorizedReducer,
        financeSimulationModels: financeSimulationModelReducer,
        stateCatalog: stateCatalogReducer,
    }),
    categoryCatalog: categoryCatalogReducer,
    customer: customerReducer,
    financialDetails: financialDetailsReducer,
    vehicleDetails: vehicleDetailsReducer,
    loyalty: loyaltyReducer,
    notification: notificationReducer,
    notificationRenewalClose: notificationRenewalCloseReducer,
    renewalNotification: renewalContractReducer,
    renewalCreditOptions: creditReducer,
    renewalLeasingOptions: leasingReducer,
    renewalPremiumCreditOptions: premiumCreditReducer,
    optionContract: renewalOptionContractReducer,
    notificationCenter: notificationCenterReducer,
    notificationCenterComponent: notificationCenterComponentReducer,
    myContact: myContactReducer,
    myMktPreferences: myMktPreferencesReducer,
    premiumCreditInfoModalSteps: premiumCreditInfoModalStepsReducer,
    rateChargePFP: RateChargePFPReducer,
    rateChargePFA: RateChargePFAReducer,
    rateChargePM: RateChargePMReducer,
    riskRate: RiskRateReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
