export type CatalogOpenRequestResponse = {
    id: string;
    category: string;
    children?: Array<string>;
    description: string;
    actionCode?: string;
    origin?: string;
    parent?: string;
    priority?: string;
    recordTypeId?: string;
    type?: string;
    typification?: string;
    subTypification?: string;
    documentsRd?: string;
    request?: string;
    sla?: string;
    salesforceOwner?: string;
    salesforceValue?: string;
    products: Array<string>;
};

export type CatalogOpenRequestFilters = {
    parentId?: string;
    actionCode?: string;
};

export type CatalogOptions = {
    id: string;
    description: string;
};

export type State = {
    stateId: string;
    stateName: string;
};

export type CatalogStatesResponse = {
    states: Array<State>;
};

export enum CategoryOpenRequestEnum {
    CASE = 'Case',
    TASK = 'Task',
}
