import {
    AbstractContentState,
    AbstractDataState,
    useCmsContent,
    useGetSimpleApiData,
} from '@cp-shared-9/frontend-integration';
import { Contract, Brand, Model, ProductCatalog } from '@cp-mx/common';
import { fetchContracts } from './ContractSlice';
import {
    brandCatalogContentSelector,
    ContractContentSelector,
    modelCatalogContentSelector,
    productCatalogContentSelector,
} from './ContractContentSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { fetchBrandCatalog } from './ContractBrandSlice';
import { fetchModelCatalog } from './ContractModelSlice';
import { fetchProductCatalog } from './ContractProductSlice';

export function useContract(): AbstractDataState<Contract[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchContracts, ContractContentSelector);
}

export function useBrandContent(): AbstractContentState<Brand> {
    return useCmsContent(fetchBrandCatalog, brandCatalogContentSelector);
}

export function useModelContent(): AbstractContentState<Model> {
    return useCmsContent(fetchModelCatalog, modelCatalogContentSelector);
}

export function useProductContent(): AbstractContentState<ProductCatalog> {
    return useCmsContent(fetchProductCatalog, productCatalogContentSelector);
}
