import { useGetSimpleUpdateData } from '@cp-shared-9/frontend-integration';
import { NotificationCenter } from '@cp-mx/common';
import { useNotificationsAllSelector } from './NotificationCenterSelector';
import { updateNotificationCenter } from './NotificationCenterComponentSlice';

type UpdateNotificationCenterType = {
    updateNotificationCenterComponent: (data: NotificationCenter[]) => void;
    notificationCenterData: NotificationCenter[];
};

export function useUpdateNotificationCenterComponent(): UpdateNotificationCenterType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateNotificationCenter, useNotificationsAllSelector);
    return {
        updateNotificationCenterComponent: updateData,
        notificationCenterData: data as NotificationCenter[],
    };
}
