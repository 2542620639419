import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

import { StateSelectionUI } from './UI/StateSelectionUI';
import { useStateSelection } from './useStateSelection';

const StateSelectionWithHandlers = withLoadingAndNoConnectionHandler(StateSelectionUI);

export const StateSelection: React.FC<{
    currentStateSelection?: string;
}> = props => {
    const { data: stateListResponse, isLoading, loadingError } = useStateSelection();
    return (
        <StateSelectionWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            statesResponse={stateListResponse}
            {...props}
        />
    );
};
