import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { RequestInitialValues } from '../../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';
import { CatalogStatesResponse, State } from '@cp-mx/common';
import { CustomSelectUI, CustomSelectElement } from '../../category-selection/UI';

export type StatesSelectionProps = {
    currentStateSelection?: string;
    statesResponse?: CatalogStatesResponse;
};

export const StateSelectionUI: React.FC<StatesSelectionProps> = ({ currentStateSelection, statesResponse }) => {
    const { t } = useTranslation('open-request');

    const [field, meta] = useField('stateSelection');
    const { setFieldValue }: FormikProps<RequestInitialValues> = useFormikContext();

    const setValueChange = (selectedCategory: string) => {
        if (selectedCategory !== currentStateSelection) {
            setFieldValue('stateSelection', selectedCategory);
        }
    };

    const getOptions = (): CustomSelectElement[] => {
        const options: CustomSelectElement[] = [
            {
                optionKey: 'no-category',
                returnValue: '',
                displayValue: t('fields.state.placeholder'),
                content: <strong>{t('fields.state.placeholder')}</strong>,
            },
        ];
        statesResponse?.states.forEach((state: State) => {
            options.push({
                optionKey: state.stateName,
                returnValue: state.stateName,
                displayValue: state.stateName,
                content: <strong>{state.stateName}</strong>,
            });
        });

        return options;
    };

    return (
        <FormField type="select" testId={'state-selection'}>
            <CustomSelectUI
                label={t('fields.state.label')}
                required
                dropdownElements={getOptions()}
                onChange={setValueChange}
                selectedKey={currentStateSelection}
            />
            {!field.value && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
        </FormField>
    );
};
