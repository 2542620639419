import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';
import { Contract } from '@cp-mx/common';
import { FinancialDetailsUi } from './ui/FinancialDetailsUi';
import { useFinancialDetails } from './useFinancialDetails';

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

export const FinancialDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { data: financialDetails, isLoading, loadingError } = useFinancialDetails(contract.contractNumber);

    return (
        <FinancialDetailsWithHandlers
            isLoading={isLoading}
            financialDetails={financialDetails}
            contract={contract}
            hasError={!!loadingError}
            component="financial-details"
        />
    );
};
