import { getProductNameCatalogEndpoint, ProductName } from '@cp-mx/common';
import { createCmsContentSlice } from '../../../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ProductName>({
    contentName: 'product-name-catalog',
    contentEndpoint: getProductNameCatalogEndpoint,
});

export default reducer;
export const fetchProductNameCatalog = fetchContent;
