import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, Button } from '@vwfs-bronson/bronson-react';
import { UnpaidDetails, formatAsCurrency } from '@cp-mx/common';
import { unpaidDetailsPath } from '../../navigation/paths';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { useAnalyticsDashboardPageTracker } from '@cp-shared-9/frontend-ui';
import { useCustomer } from '../../../auth/customer';

export const UnpaidBalanceUi: React.FC<{ unpaidDetails?: UnpaidDetails }> = ({ unpaidDetails }) => {
    const { t } = useTranslation('unpaid-balance');
    const { data: customerId } = useCustomer();
    const history = useHistory();
    const amount: number = unpaidDetails ? unpaidDetails.unpaidAmount : 0;
    const shown = amount ? amount > 0 : false;
    const handleClick = () => history.push(unpaidDetailsPath());
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;
    useAnalyticsDashboardPageTracker(true, shown, lastPathname);
    return (
        <>
            <Notification
                visible={shown}
                showCloseButton={false}
                status={'warning'}
                title={t('headline')}
                testId={'notificationWithNavButton'}
            >
                {t('bodyFirstPart')}
                <b>{formatAsCurrency(Number(amount))}</b>
                {t('bodySecondPart')}
                <br />
                <br />
                {t('content')}
                <b>
                    <a href={`mailto:cc@vwfs.com?subject=Atención a clientes FS Online-Cliente ${customerId}`}>
                        {t('email')}
                    </a>
                </b>
                .
                <Button
                    secondary
                    className="u-bg-yellow u-mt o-layout__item u-1/3 u-1/1@s u-1/2@l"
                    testId="seeUnpaidDetailsButton"
                    onClick={handleClick}
                >
                    {t('button')}
                </Button>
            </Notification>
            <br />
        </>
    );
};
