import { SimplePage, getPrivacyPolicyEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePage>({
    contentName: 'privacyPolicy',
    contentEndpoint: getPrivacyPolicyEndpoint,
});

export default reducer;
export const fetchPrivacyPolicy = fetchContent;
