import { FaqPageTeaser, getFaqTeaserEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<FaqPageTeaser>({
    contentName: 'FaqTeaser',
    contentEndpoint: getFaqTeaserEndpoint,
});

export default reducer;
export const fetchFaqTeaser = fetchContent;
