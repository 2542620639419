import { createGetContractBasedDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { RiskClassResponse } from '@cp-mx/common';

const { reducer: RiskRateReducer, fetchData: fetchDataRiskRate } = createGetContractBasedDataFetchSlice<
    RiskClassResponse,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'riskRate',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default RiskRateReducer;
export const fetchRiskRate = fetchDataRiskRate;
