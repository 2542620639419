/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { CategoryTypeEnum, RequestInitialValues } from '../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';
import { CatalogOpenRequestResponse, CategoryCatalogDropDown, CategoryDropDown } from '@cp-mx/common';
import { CustomSelectUI, CustomSelectElement } from './UI';

export type CategoryRequestSelectionProps = {
    currentCategorySelection?: string;
    dataCategoryRequest: Array<CatalogOpenRequestResponse>;
    setParentId: Function;
    setCategoryType: Function;
};

export const CategoryRequestSelection: React.FC<CategoryRequestSelectionProps> = ({
    currentCategorySelection,
    dataCategoryRequest,
    setParentId,
    setCategoryType,
}) => {
    const [field, meta] = useField('categoryTypeSelection');

    const { setFieldValue }: FormikProps<RequestInitialValues> = useFormikContext();

    const categorySelection = (): CategoryCatalogDropDown => {
        const arrayCategory: Array<CategoryDropDown> = [];
        dataCategoryRequest?.forEach(elem => {
            arrayCategory.push({
                name: elem.description,
                id: elem.description,
            });
        });
        return {
            categories: arrayCategory,
        };
    };
    const { t } = useTranslation('open-request');

    const setValueChange = (selectedCategory: string) => {
        if (selectedCategory !== currentCategorySelection) {
            const categoryType = dataCategoryRequest.find(elem => elem.description === selectedCategory);
            if (categoryType?.category === CategoryTypeEnum.SECTION) {
                setParentId(categoryType.id);
            }
            setFieldValue('categoryTypeSelection', selectedCategory);
            setCategoryType(categoryType?.category);
        }
    };

    const getOptions = (): CustomSelectElement[] => {
        const options: CustomSelectElement[] = [
            {
                optionKey: 'no-category',
                returnValue: '',
                displayValue: t('fields.categoryRequest.placeholder'),
                content: <strong>{t('fields.categoryRequest.placeholder')}</strong>,
            },
        ];
        categorySelection().categories.forEach(category => {
            options.push({
                optionKey: category.id,
                returnValue: category.id,
                displayValue: category.name,
                content: <strong>{category.name}</strong>,
            });
        });
        return options;
    };

    useEffect(() => {
        if (getOptions().length && dataCategoryRequest.length === 1) {
            setFieldValue('categoryTypeSelection', dataCategoryRequest[0].description);
        }
    }, [dataCategoryRequest]);

    return (
        <>
            {dataCategoryRequest.length ? (
                <FormField type="select" testId={'category-selection'}>
                    <CustomSelectUI
                        label={t('fields.categoryRequest.label')}
                        required
                        dropdownElements={getOptions()}
                        onChange={setValueChange}
                        selectedKey={currentCategorySelection}
                    />
                    {!field.value && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
                </FormField>
            ) : null}
        </>
    );
};
