import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RenewalNotificationUi } from './ui';
import { useRenewalContract } from './useRenewalContract';

const RenewalNotificationWithHandlers = withLoadingAndNoConnectionHandler(RenewalNotificationUi);

export const RenewalNotification: React.FC = () => {
    const { data, isLoading } = useRenewalContract();
    return <RenewalNotificationWithHandlers isLoading={isLoading} renewalContract={data} hasError={false} />;
};
