import { Loyalty, getLoyaltiesEndpoint } from '@cp-mx/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<Loyalty[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'loyalty',
    fetchCallback() {
        return CpDataApi.get<Loyalty[]>(`${getLoyaltiesEndpoint()}?valid=true`).then(response => response.data);
    },
});

export default reducer;
export const fetchLoyalty = fetchData;
