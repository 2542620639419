export enum CategoryTypeEnum {
    INITIAL = '',
    SECTION = 'section',
    TASK = 'task',
    CASE = 'case',
}

export enum RequestInitialValuesEnum {
    CONTRACT_SELECTION = 'contractSelection',
    CATEGORY_MAIN_SELECTION = 'categoryMainSelection',
    CATEGORY_TYPE_SELECTION = 'categoryTypeSelection',
    DOCUMENTS_CHECK = 'documentsCheck',
    FORMAT_CHECK = 'formatCheck',
    STATE_SELECTION = 'stateSelection',
    SHORT_DESCRIPTION = 'inputField1',
}

export type RequestInitialValues = {
    [RequestInitialValuesEnum.CONTRACT_SELECTION]: string;
    [RequestInitialValuesEnum.CATEGORY_MAIN_SELECTION]: string;
    [RequestInitialValuesEnum.CATEGORY_TYPE_SELECTION]: string;
    [RequestInitialValuesEnum.DOCUMENTS_CHECK]: Array<string>;
    [RequestInitialValuesEnum.FORMAT_CHECK]: Array<string>;
    [RequestInitialValuesEnum.STATE_SELECTION]: string;
    [RequestInitialValuesEnum.SHORT_DESCRIPTION]: string;
};

export const getInitialValues: () => RequestInitialValues = () => ({
    contractSelection: '',
    categoryMainSelection: '',
    categoryTypeSelection: '',
    documentsCheck: [],
    formatCheck: [],
    stateSelection: '',
    inputField1: '',
});

export enum ShortDescriptionEnum {
    CAPITAL = 'Abono a capital',
    COMMENT_SUGGESTION = 'Quejas y sugerencias',
    PORTAL = 'Portal de descarga de facturas y estados de cuenta',
    INSURANCE_CHANGE = 'Solicitud de cambio de aseguradora',
}

export const shortDescriptionPlaceholder = {
    [ShortDescriptionEnum.CAPITAL]: 'Favor de indicar el monto que desea abonar',
    [ShortDescriptionEnum.COMMENT_SUGGESTION]:
        'Favor de especificar el detalle de su queja, incluida la fecha de ocurrencia',
    [ShortDescriptionEnum.PORTAL]:
        'Favor de especificar su problema en el portal. Por ejemplo: No recuerda su contraseña, no recuerda su número de contrato, no puede accesar etc.',
    [ShortDescriptionEnum.INSURANCE_CHANGE]: 'Favor de especificar el motivo de cambio de aseguradora',
};

export const formatOptions = [
    { id: 'digital', description: 'Formato digital' },
    { id: 'fisico', description: 'En físico' },
];
