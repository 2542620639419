import { Faq } from '../../components/faq';
import { FaqTeaser } from '../../components/faq-teaser';
import React from 'react';

/**
 * View responsible for providing the FAQ view component with its corresponding hero teaser
 */
export const FaqPage: React.FC = () => {
    return (
        <div>
            <FaqTeaser />
            <Faq />
        </div>
    );
};
