import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RegistrationError, RegistrationResult } from '@cp-mx/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { CleaveInput, preventSubmit, ValidatedCheckbox } from '@cp-shared-9/frontend-ui';
import { personIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { PersonFormProps } from '../types';
import { legalNoticePath, privacyPolicyPath } from '../../../../navigation/paths';

type PersonIdentificationFormProps = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: PersonFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const PersonIdentificationForm: React.FC<PersonIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const translationPrefix = 'registration-form.person.input-labels';

    const onSubmit = (formValues: PersonFormProps): void => {
        onSubmitClick();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        privateCustomerNumber: {
            text: `${t(`${translationPrefix}.customer-number.label`)} `,
            tooltip: t(`${translationPrefix}.customer-number.tooltip`),
        },
        dateOfBirth: {
            text: `${t(`${translationPrefix}.birth-date.label`)} `,
            tooltip: t(`${translationPrefix}.birth-date.tooltip`),
        },
        confirmTermsAndConditions: {
            text: t(`${translationPrefix}.agreements.terms-and-conditions.label`),
            link: t(`${translationPrefix}.agreements.terms-and-conditions.link`),
            tooltip: t(`${translationPrefix}.agreements.terms-and-conditions.tooltip`),
        },
        confirmPrivacyPolicy: {
            text: t(`${translationPrefix}.agreements.privacy-policy.label`),
            link: t(`${translationPrefix}.agreements.privacy-policy.link`),
            tooltip: t(`${translationPrefix}.agreements.privacy-policy.tooltip`),
        },
    };

    return (
        <Layout.Item>
            <Formik
                initialValues={{
                    privateCustomerNumber: '',
                    dateOfBirth: '',
                    confirmTermsAndConditions: false,
                    confirmPrivacyPolicy: false,
                }}
                validationSchema={personIdentificationFormValidationSchema(t)}
                onSubmit={onSubmit}
            >
                {formik => (
                    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => preventSubmit(e)}>
                        <Fieldset>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.privateCustomerNumber.text}
                                    tooltip={labels.privateCustomerNumber.tooltip}
                                    name={'privateCustomerNumber'}
                                    testId={'private-customer-number'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '/',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.dateOfBirth.text}
                                    tooltip={labels.dateOfBirth.tooltip}
                                    name={'dateOfBirth'}
                                    testId={'date-of-birth'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {`${labels.confirmTermsAndConditions.text} `}
                                            <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmTermsAndConditions.link}
                                            </a>
                                        </>
                                    }
                                    name="confirmTermsAndConditions"
                                    testId={'private-confirm-terms-and-conditions'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {`${labels.confirmPrivacyPolicy.text} `}
                                            <a href={privacyPolicyPath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmPrivacyPolicy.link}
                                            </a>
                                        </>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={'private-confirm-privacy-policy'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                    testId={'submit-private-registration-button'}
                                >
                                    {t(`registration-form.button-labels.confirm`)}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Layout.Item>
    );
};
