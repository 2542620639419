import {
    AbstractContentState,
    AbstractDataState,
    hasFetchedSuccessfully,
    useCmsContent,
    useGetContractBasedApiData,
} from '@cp-shared-9/frontend-integration';
import { DefaultMarketApiErrors } from '@cp-shared-9/common-utilities';
import {
    Contract,
    getRateChargeEndpoint,
    getRiskClassEndpoint,
    Model,
    RateCharge,
    RiskClassResponse,
} from '@cp-mx/common';
import { useMemo } from 'react';
import { useContractEspecific } from '../contracts/useContracts';
import { fetchRateChargePFP } from './RateChargePFPSlice';
import { useLoyalty } from '../loyalty-notification/useLoyaltyNotification';
import {
    FinanceSimulationModelCatalogContentSelector,
    rateChargePFADataSelector,
    rateChargePFPDataSelector,
    rateChargePMDataSelector,
    riskRateSelector,
} from './FinanceSimulationContentSelector';
import { fetchRateChargePM } from './RateChargePMSlice';
import { fetchRateChargePFA } from './RateChargePFASlice';
import { fetchRiskRate } from './RiskRateSlice';
import { fetchFinanceSimulationModelCatalog } from './FinanceSimulationModelSlice';

export function useRateChargePFP(contractId: string): AbstractDataState<RateCharge, DefaultMarketApiErrors> {
    const contractState = useContractEspecific(contractId);
    const { data: loyalty } = useLoyalty();
    const program = loyalty && loyalty?.length > 0 ? 'fs xclusive' : 'normal';
    const rateChargePFPState = useGetContractBasedApiData(
        contractId,
        fetchRateChargePFP,
        rateChargePFPDataSelector,
        `${getRateChargeEndpoint()}?program=${program}&year=${
            contractState.data?.vehicle.year
        }&brand=${contractState.data?.vehicle.brand.toLocaleLowerCase()}&profile=PFP`,
    );

    return useMemo(
        () => ({
            isLoading: contractState.isLoading || rateChargePFPState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && rateChargePFPState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? rateChargePFPState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: rateChargePFPState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? rateChargePFPState.loadingError
                : contractState.loadingError,
        }),
        [contractState, rateChargePFPState],
    );
}

export function useRateChargePFA(contractId: string): AbstractDataState<RateCharge, DefaultMarketApiErrors> {
    const contractState = useContractEspecific(contractId);
    const { data: loyalty } = useLoyalty();
    const program = loyalty && loyalty?.length > 0 ? 'fs xclusive' : 'normal';
    const rateChargePFAState = useGetContractBasedApiData(
        contractId,
        fetchRateChargePFA,
        rateChargePFADataSelector,
        `${getRateChargeEndpoint()}?program=${program}&year=${
            contractState.data?.vehicle.year
        }&brand=${contractState.data?.vehicle.brand.toLocaleLowerCase()}&profile=PFA`,
    );

    return useMemo(
        () => ({
            isLoading: contractState.isLoading || rateChargePFAState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && rateChargePFAState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? rateChargePFAState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: rateChargePFAState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? rateChargePFAState.loadingError
                : contractState.loadingError,
        }),
        [contractState, rateChargePFAState],
    );
}
export function useRateChargePM(contractId: string): AbstractDataState<RateCharge, DefaultMarketApiErrors> {
    const contractState = useContractEspecific(contractId);
    const { data: loyalty } = useLoyalty();
    const program = loyalty && loyalty?.length > 0 ? 'fs xclusive' : 'normal';
    const rateChargePMState = useGetContractBasedApiData(
        contractId,
        fetchRateChargePM,
        rateChargePMDataSelector,
        `${getRateChargeEndpoint()}?program=${program}&year=${
            contractState.data?.vehicle.year
        }&brand=${contractState.data?.vehicle.brand.toLocaleLowerCase()}&profile=PM`,
    );

    return useMemo(
        () => ({
            isLoading: contractState.isLoading || rateChargePMState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && rateChargePMState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? rateChargePMState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: rateChargePMState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? rateChargePMState.loadingError
                : contractState.loadingError,
        }),
        [contractState, rateChargePMState],
    );
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */

export function useRiskRate(
    contract: Contract,
    model: string,
): AbstractDataState<RiskClassResponse | undefined, DefaultMarketApiErrors> {
    const rateRiskRateState = useGetContractBasedApiData(
        contract.contractNumber,
        fetchRiskRate,
        riskRateSelector,
        `${getRiskClassEndpoint()}?model=${model}`,
    );
    return useMemo(
        () => ({
            isLoading: rateRiskRateState.isLoading,
            hasReceivedResponse: rateRiskRateState.loadingError
                ? rateRiskRateState.hasReceivedResponse
                : rateRiskRateState.hasReceivedResponse && rateRiskRateState.hasReceivedResponse,
            failedLoadingAttempts: rateRiskRateState.failedLoadingAttempts,
            data: rateRiskRateState.data,
            loadingError: rateRiskRateState.loadingError,
        }),
        [rateRiskRateState],
    );
}

export function useFinanceSimulationModelContent(): AbstractContentState<Model> {
    return useCmsContent(fetchFinanceSimulationModelCatalog, FinanceSimulationModelCatalogContentSelector);
}
