import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { CategoryTypeEnum } from './initialValues';

export const validationSchema = (t: TFunction, categoryType: string) => {
    let schemaShape = {
        contractSelection: Yup.string()
            .required(t('fields.contracts.default'))
            .test('test_select_contract', t('fields.contracts.label'), value => {
                if (value && value.toString() === t('fields.contracts.default')) {
                    return false;
                }
                return true;
            }),
        categoryMainSelection: Yup.string().required(t('fields.category.errors.required')),
        categoryTypeSelection: Yup.string().required(t('fields.categoryRequest.errors.required')),
        stateSelection: Yup.string().when('categoryTypeSelection', {
            is: (val: string) => val && val.includes('Solicitud de documentos de arrendamiento'),
            then: () => Yup.string().required(t('fields.state.errors.required')),
            otherwise: () => Yup.string(),
        }),
        formatCheck: Yup.string().when('categoryTypeSelection', {
            is: (val: string) =>
                val && ['Solicitud de documentos generales', 'Solicitud de documentos de arrendamiento'].includes(val),
            then: () =>
                Yup.array()
                    .min(1, t('fields.formatCheck.errors.required'))
                    .required(),
            otherwise: () => Yup.array(),
        }),
        inputField1: Yup.string()
            .trim()
            .required(t('fields.inputField1.errors.required'))
            .max(1000, t('fields.inputField1.errors.max')),
    };

    if (categoryType === CategoryTypeEnum.SECTION) {
        schemaShape = {
            ...schemaShape,
            ...{
                documentsCheck: Yup.array()
                    .min(1, t('fields.documentsCheck.errors.required'))
                    .required(),
            },
        };
    }

    return Yup.object().shape(schemaShape);
};
