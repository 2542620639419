import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { FormView } from './form-view/FormView';
import { useContract } from '../contracts';

export const Request: React.FC<object> = () => {
    const { data: contracts, isLoading, loadingError } = useContract();

    const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

    return <RequestWithHandlers isLoading={isLoading} contracts={contracts} hasError={!!loadingError} />;
};
