import { CpDataApi } from '../../cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-9/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

import { getGreetingEndpoint } from '@cp-mx/common';

import { GreetingWithAllValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: GreetingWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const GreetingMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getGreetingEndpoint(),
};

export const GreetingResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [GreetingMock]);

    return <div>{storyFn()}</div>;
};
