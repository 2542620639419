/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { RequestInitialValues } from '../../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';
import { CatalogOpenRequestResponse, CategoryCatalogDropDown, CategoryDropDown } from '@cp-mx/common';
import { CustomSelectUI, CustomSelectElement } from '.';

export type CategorySelectionProps = {
    currentCategorySelection?: string;
    contractProduct: string;
    setParentId: Function;
    setCategoryType: Function;
    data?: Array<CatalogOpenRequestResponse>;
};

export const CategorySelectionUI: React.FC<CategorySelectionProps> = ({
    currentCategorySelection,
    contractProduct,
    setParentId,
    setCategoryType,
    data,
}) => {
    const { t } = useTranslation('open-request');
    const { setFieldValue }: FormikProps<RequestInitialValues> = useFormikContext();
    const [field, meta] = useField('categoryMainSelection');

    const categoryResponse = data?.filter(category => {
        if (contractProduct && category.products.includes(contractProduct.trim())) {
            return category;
        }
    });

    if (!categoryResponse?.length) {
        return null;
    }

    const categorySelection = (): CategoryCatalogDropDown => {
        const arrayCategory: Array<CategoryDropDown> = [];
        categoryResponse?.forEach(elem => {
            arrayCategory.push({
                name: elem.description,
                id: elem.description,
            });
        });
        return {
            categories: arrayCategory,
        };
    };
    const setValueChange = (selectedCategory: string) => {
        if (selectedCategory !== currentCategorySelection) {
            const categorySelection = categoryResponse?.find(elem => elem.description === selectedCategory);
            setParentId(categorySelection?.id);
            setFieldValue('categoryMainSelection', selectedCategory);
            setFieldValue('categoryTypeSelection', '');
            setCategoryType(categorySelection?.category);
        }
    };

    const getOptions = (): CustomSelectElement[] => {
        const options: CustomSelectElement[] = [
            {
                optionKey: 'no-category',
                returnValue: '',
                displayValue: t('fields.category.placeholder'),
                content: <strong>{t('fields.category.placeholder')}</strong>,
            },
        ];

        categorySelection().categories.forEach((category: CategoryDropDown) => {
            options.push({
                optionKey: category.id,
                returnValue: category.id,
                displayValue: category.name,
                content: <strong>{category.name}</strong>,
            });
        });
        return options;
    };

    return (
        <FormField type="select" testId={'category-selection'}>
            <CustomSelectUI
                label={t('fields.category.label')}
                required
                dropdownElements={getOptions()}
                onChange={setValueChange}
                selectedKey={currentCategorySelection}
            />
            {!field.value && meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
        </FormField>
    );
};
