import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsDate, formatAsCurrency, Contract } from '@cp-mx/common';
import {
    ContractSummaryItemProps,
    ContractSummary,
    ContractActions,
    ContractActionItemType,
    useAnalyticsActionTracker,
} from '@cp-shared-9/frontend-ui';
import { Card, Notification } from '@vwfs-bronson/bronson-react';
import { useModelContent, useBrandContent } from '..';
import { useProductContent } from '../useContract';
import { ContractDetails } from '../contract-details';
import { paymentDate } from '../utils';
import moment from 'moment-timezone';
import { openRequestPath } from '../../navigation/paths';
import { Link } from 'react-router-dom';
import { useGreeting } from '../../greeting';

const dateFormat = 'YYYY-MM-DD';

export const ContractOverview: React.FC<{ contract: Contract; defaultExpanded: boolean }> = ({
    contract,
    defaultExpanded,
}) => {
    const { cmsContent: modelCatalog } = useModelContent();
    const { cmsContent: brandCatalog } = useBrandContent();
    const { cmsContent: productCatalog } = useProductContent();
    const { data: dataGreeting } = useGreeting();
    const { onAction: onClickButton } = useAnalyticsActionTracker('onPay');
    const { t } = useTranslation('contracts');
    moment.tz.setDefault('America/Mexico_City');
    if (!contract) {
        return null;
    }

    const contractEnd = !!moment(contract.contractEndDate, dateFormat).isBefore(moment());

    const productFromCatalog = (contract: Contract): string => {
        if (contract.product.type) {
            return contract.product.type.concat(' ' + contract.contractLabel);
        }
        return productCatalog ? productCatalog[contract.product.group].concat(' ' + contract.contractLabel) : '-';
    };

    const brand =
        brandCatalog && brandCatalog[contract.vehicle.brand]
            ? brandCatalog[contract.vehicle.brand]
            : contract.vehicle.brand;
    const model =
        modelCatalog && modelCatalog[contract.vehicle.model]
            ? modelCatalog[contract.vehicle.model]
            : contract.vehicle.model;
    const paymentDay = contract.paymentDay ? formatAsDate(paymentDate(contract)) : '-';
    const paymentInstallment = contract.paymentInstallment
        ? formatAsCurrency(Number(contract.paymentInstallment))
        : '-';
    const contractEndDate = contract.contractEndDate ? formatAsDate(contract.contractEndDate) : '-';
    const title = brand.concat(' ' + model);
    const subtitle = contract.product.name
        ? contract.product.name.concat(' ' + contract.contractLabel)
        : productFromCatalog(contract);
    const productType = contract.product.type;
    const financedAmount = contract.financedAmount;

    const getActionItems = (): ContractSummaryItemProps[] => {
        const actionItems: ContractSummaryItemProps[] = [
            {
                value: paymentDay,
                label: t('next-payment-date'),
                testClass: 'next-payment-date',
            },
            {
                value: paymentInstallment,
                label: t('next-payment-amount'),
                testClass: 'next-payment-amount',
            },
        ];
        if (productType === 'Leasing') {
            actionItems.push({
                value: contractEndDate,
                label: t('contract-end-date'),
                testClass: 'contract-end-date',
            });
        } else {
            if (financedAmount > 0) {
                actionItems.push({
                    value: formatAsCurrency(Number(financedAmount)),
                    label: t('financed-amount'),
                    testClass: 'financed-amount',
                });
            }
        }

        return actionItems;
    };

    const getActions = (): ContractActionItemType[] => {
        const actionItems: ContractActionItemType[] = [];
        if (!dataGreeting?.isAutomaticPayment) {
            actionItems.push({
                iconName: 'semantic-accessoires',
                label: t('payment-reference-button'),
                onClick: () => {
                    onClickButton();
                    window.open(t('payment-external-url'));
                },
                testId: 'paymentReferenceUrl',
            });
        }
        return actionItems;
    };

    return (
        <Card
            element="article"
            expandable
            title={title}
            subtitle={subtitle}
            contentShort={''}
            defaultExpanded={defaultExpanded}
            className={'u-mb'}
            contentLarge={<ContractDetails contract={contract} />}
        >
            {contractEnd ? (
                <Notification
                    visible={true}
                    showCloseButton={false}
                    status={'success'}
                    title={t('headline')}
                    testId={'notificationWithNavButton'}
                >
                    {t('body')} <Link to={openRequestPath()}> aquí</Link>
                </Notification>
            ) : (
                <>
                    <ContractSummary items={getActionItems()} />
                    <ContractActions contractActionItems={getActions()} className="u-mt-none u-mb" />
                </>
            )}
        </Card>
    );
};
