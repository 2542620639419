import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroImage } from '@cp-shared-9/frontend-ui';
import { SimplePage, SimpleTeaser, SimplePageContent } from '@cp-mx/common';

export const LegalNoticeTeaserUI: React.FC<{ teaser?: SimpleTeaser }> = ({ teaser }) =>
    teaser ? (
        <HeroImage
            title={teaser.title}
            imageUrl={teaser.imageUrl}
            shallow={true}
            inverted={!!teaser.isInverted}
            subTitle={teaser.subTitle}
        />
    ) : null;

export const LegalNoticeContent: React.FC<{ content?: SimplePageContent }> = ({ content }) =>
    content ? (
        <>
            {Object.keys(content).map((section: string) => (
                <ContentSection key={section} pageWrapSize="medium">
                    <div dangerouslySetInnerHTML={{ __html: content[section] }} />
                </ContentSection>
            ))}
        </>
    ) : null;

export const LegalNoticeUi: React.FC<{ legalNotice?: SimplePage }> = ({ legalNotice }) =>
    legalNotice ? (
        <>
            <LegalNoticeTeaserUI teaser={legalNotice.teaser} />
            <LegalNoticeContent content={legalNotice.content} />
        </>
    ) : null;
