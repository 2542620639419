import { RenewalOption, RenewalPremiumCreditInfoModalStep } from '@cp-mx/common';
import { AbstractContentState, useCmsContent } from '@cp-shared-9/frontend-integration';
import {
    fetchPremiumCreditInfoModalSteps,
    fetchRenewalCreditOptions,
    fetchRenewalLeasingOptions,
    fetchRenewalPremiumCreditOptions,
} from './RenewalOptionsSlice';
import {
    PremiumCreditInfoModalStepsSelector,
    RenewalCreditSelector,
    RenewalLeasingSelector,
    RenewalPremiumCreditSelector,
} from './RenewalOptionsSelector';

export function useRenewalCreditOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalCreditOptions, RenewalCreditSelector);
}

export function useRenewalLeasingOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalLeasingOptions, RenewalLeasingSelector);
}

export function useRenewalPremiumCreditOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalPremiumCreditOptions, RenewalPremiumCreditSelector);
}

export function useRenewalPremiumCreditInfoModalSteps(): AbstractContentState<RenewalPremiumCreditInfoModalStep[]> {
    return useCmsContent(fetchPremiumCreditInfoModalSteps, PremiumCreditInfoModalStepsSelector);
}
