import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency } from '@cp-mx/common';
import { ContentSection, Button, PageWrap, Heading } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../navigation/paths';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { useUnpaidBalance } from '../useUnpaidBalance';

export const UnpaidBalanceDetailsContent: React.FC<{
    headline: string;
    body: string;
    button: string;
    handleClick: () => void;
}> = ({ headline, body, button, handleClick }) => (
    <PageWrap size={'xsmall'} className="u-mt-large u-text-center">
        <h4>{headline}</h4>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <div className={'o-layout o-layout--right'}>
            <Button className="u-bg-blue u-mt  o-layout__item u-1/4" testId="backToDashboard" onClick={handleClick}>
                {button}
            </Button>
        </div>
    </PageWrap>
);

export const UnpaidBalanceDetails: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation('unpaid-balance-details');
    const handleClick = () => history.push(dashboardPagePath());
    useAnalyticsPageViewTracker('unpaidDetails', true);
    const { data: unpaidDetails } = useUnpaidBalance();
    const text = t('subheadline').replace(
        '<unpaidAmount>',
        unpaidDetails ? formatAsCurrency(Number(unpaidDetails.unpaidAmount)) : '0',
    );
    return (
        <>
            <ContentSection pageWrap>
                <Heading level={1} className="u-mb-large u-text-center">
                    {t('headline')}
                </Heading>
                <UnpaidBalanceDetailsContent
                    headline={text}
                    body={t('body')}
                    button={t('button')}
                    handleClick={handleClick}
                />
            </ContentSection>
        </>
    );
};
