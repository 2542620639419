import {
    Brand,
    calculateResponse,
    ContactData,
    Contract,
    FinanceSimulationRequest,
    formatAsCurrency,
    getCalculationEndpoint,
    getContactDataEndpoint,
    getCreateRequestEndpoint,
    getSendFinanceSimulationMailEndpoint,
    Loyalty,
    Model,
    MyProfileData,
    RateCharge,
    RiskClassResponse,
    termRateCharge,
} from '@cp-mx/common';
import {
    NotificationStatus,
    ValidatedCheckboxGroup,
    Notification,
    Spinner,
    ValidatedCheckbox,
    useAuthentication,
} from '@cp-shared-9/frontend-ui';
import {
    Button,
    ContentSection,
    CustomRangeSlider,
    Heading,
    Layout,
    Modal,
    TileSelect,
    TileSelectGroup,
    TimePicker,
    ToastNotification,
    ToastNotifications,
    useToastOpen,
} from '@vwfs-bronson/bronson-react';
import { CpDataApi } from '../../../cp-xhr';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath, myProfilePagePath } from '../../navigation/paths';
import _ from 'lodash';
import { useFinancialDetails } from '../../contracts/contract-details/financial-details';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { incomeOptions, monthsInitialValues, numbers } from '../../../const';

export const calculationInitialValues = {
    cat: '',
    ordinaryRate: '',
    monthlyPayment: numbers.zero,
    cpi: numbers.zero,
    insuranceMonthlyPayment: numbers.zero,
};

export interface FinanceSimulationCalculatorForm {
    incomeSelected: boolean;
    insurance: boolean;
    recibosDeNomina: boolean;
    estadoDeCuenta: boolean;
    representoUnaEmpresa: boolean;
    product: string;
    shiftSelected: string;
    months: never[];
    privacyCheck: boolean;
}
type EventCheckBox = React.ChangeEvent<HTMLInputElement>;

const ModalMessage: React.FC<{
    shown: boolean;
    onConfirm: () => void;
}> = ({ shown, onConfirm }) => {
    const { t } = useTranslation('finance-simulation');

    return (
        <Modal
            hideCloseButton
            shown={shown}
            onConfirm={() => onConfirm()}
            buttonConfirmText={'Aceptar'}
            testId={'confirm-modal'}
        >
            <label className="u-text-brand">{t('success')}</label>
        </Modal>
    );
};

export const FinanceSimulationCalculatorUi: React.FC<{
    isLoadingPage: boolean;
    rateChargePFP?: RateCharge;
    rateChargePFA?: RateCharge;
    rateChargePM?: RateCharge;
    riskRate?: RiskClassResponse;
    contract?: Contract;
    profile?: MyProfileData;
    modelCatalog?: Model;
    brandCatalog?: Brand;
    loyalty?: Loyalty[];
    customerId?: string;
}> = ({
    isLoadingPage,
    rateChargePFP,
    rateChargePFA,
    rateChargePM,
    riskRate,
    contract,
    profile,
    modelCatalog,
    brandCatalog,
    loyalty,
    customerId,
}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState<boolean>(isLoadingPage);
    const [checkedPremiumCredit, setCheckedPremiumCredit] = useState<boolean>(false);
    const [checkedCredit, setCheckedCredit] = useState<boolean>(false);
    const [productCount, setProductCount] = useState<number>(numbers.one);
    const contractNumber = contract ? contract.contractNumber : '';
    const { data: financialDetails } = useFinancialDetails(contractNumber);
    const [rateCharge, setRateCharge] = useState<RateCharge>({
        credit: {},
        premiumCredit: {},
    });
    const [months, setMonths] = useState<Array<number>>([]);
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [finalPayment, setFinalPayment] = useState<number>(numbers.zero);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState<Array<number>>([]);
    const [downPayment, setDownPayment] = useState<number>(numbers.zero);
    const [term, setTerm] = useState<number>(numbers.zero);
    const [downPaymentSelected, setDownPaymentSelected] = useState<number>(numbers.zero);
    const [product, setProduct] = useState<string>('');
    const [insurance, setInsurance] = useState<boolean>(false);
    const [responseCalculation, setCalculation] = useState<calculateResponse>(calculationInitialValues);
    const { props: toastPropsError, open: openError, setOpen: setOpenError } = useToastOpen(false);
    const [shownModal, setShownModal] = useState(false);
    const history = useHistory();
    const backDashboard = () => history.push(dashboardPagePath());
    const auth = useAuthentication();
    const { t } = useTranslation('finance-simulation');
    let brand = '';
    let model = '';
    let car = '';
    let loyaltyContract: Loyalty;
    const ballonPayment = contract?.balloonPayment ? contract?.balloonPayment : numbers.zero;
    let finalAmount = '';
    const typeOfPerson = profile ? (profile?.isPhysicalPerson ? 'PFA' : 'PM') : '';
    if (contract) {
        brand =
            brandCatalog && brandCatalog[contract.vehicle.brand]
                ? brandCatalog[contract.vehicle.brand]
                : contract.vehicle.brand;
        model =
            modelCatalog && modelCatalog[contract.vehicle.model]
                ? modelCatalog[contract.vehicle.model]
                : contract.vehicle.model;
        car = brand.concat(' ' + model);
        finalAmount = t('finalAmount').replace('<ballonPayment>', formatAsCurrency(contract.balloonPayment));
    }
    if (loyalty && contract) {
        loyalty.forEach(loyalItem => {
            if (loyalItem.contractNumber === contract.contractLabel || loyalItem.contractNumber === 'ALL') {
                if (loyalItem.preAuthorizedMonthlyPayment > numbers.zero) {
                    loyaltyContract = loyalItem;
                }
            }
        });
    }

    const countProduct = (rateCharge: RateCharge) => {
        if (
            Object.keys(rateCharge.credit).length > numbers.zero &&
            Object.keys(rateCharge.premiumCredit).length > numbers.zero
        ) {
            return numbers.two;
        } else {
            return numbers.one;
        }
    };

    const createArrayMonths = (value: string): Array<number> => {
        const arrayMonths: Array<number> = [numbers.zero];
        if (value === 'Credit') {
            Object.keys(rateCharge.credit).forEach(month => {
                if (Number(financialDetails?.term) + Number(month) <= numbers.sixty) {
                    arrayMonths.push(Number(month));
                }
            });
        } else {
            Object.keys(rateCharge.premiumCredit).forEach(month => {
                if (Number(financialDetails?.term) + Number(month) <= numbers.sixty && riskRate && riskRate[month]) {
                    arrayMonths.push(Number(month));
                }
            });
        }
        return arrayMonths;
    };

    const calculateMonths = (downPayment: number, months: number[], set: boolean): number[] => {
        const arrayMonths: number[] = [];
        if (set) {
            arrayMonths.push(numbers.zero);
        }
        if (riskRate) {
            Object.keys(riskRate).forEach(month => {
                if (!isNaN(Number(month))) {
                    const porcentajeRisk = (riskRate[month] as number) / numbers.oneHundred;
                    const valorFinal = ballonPayment * porcentajeRisk;
                    const total = valorFinal + downPayment;
                    if (total < ballonPayment && months.includes(Number(month))) {
                        arrayMonths.push(Number(month));
                    }
                }
            });
        }
        return arrayMonths;
    };

    const renderOptions = (): (JSX.Element | undefined)[] => {
        return incomeOptions.map((option, index) => {
            if (typeOfPerson !== 'PM') {
                if (
                    _.camelCase(option) === 'recibosDeNomina' &&
                    rateChargePFP &&
                    Object.keys(rateChargePFP.credit).length === numbers.zero &&
                    Object.keys(rateChargePFP.premiumCredit).length === numbers.zero
                ) {
                    return undefined;
                }
                if (
                    _.camelCase(option) === 'estadoDeCuenta' &&
                    rateChargePFA &&
                    Object.keys(rateChargePFA.credit).length === numbers.zero &&
                    Object.keys(rateChargePFA.premiumCredit).length === numbers.zero
                ) {
                    return undefined;
                }
                if (_.camelCase(option) === 'representoUnaEmpresa') {
                    return undefined;
                }
            } else {
                if (_.camelCase(option) === 'estadoDeCuenta' || _.camelCase(option) === 'recibosDeNomina') {
                    return undefined;
                }
            }
            return (
                <Layout.Item
                    key={index}
                    default={!profile?.isPhysicalPerson ? '1/1' : '1/2'}
                    m={'1/2'}
                    s="1/1"
                    xs="1/1"
                    className={'u-p-none'}
                >
                    <ValidatedCheckboxGroup.Checkbox
                        label={option}
                        groupName={'incomeOptions'}
                        name={_.camelCase(option)}
                        disabled={typeOfPerson === 'PM'}
                        testId={_.camelCase(option)}
                    />
                </Layout.Item>
            );
        });
    };

    const changeCheckBox = async (value: EventCheckBox, formik: FormikProps<FinanceSimulationCalculatorForm>) => {
        setIsLoading(true);
        formik.setFieldValue('product', '');
        setProduct('');
        setCheckedCredit(false);
        setCheckedPremiumCredit(false);
        setShowProducts(false);
        if (value.target.name === 'recibosDeNomina' && value.target.checked) {
            formik.setFieldValue('estadoDeCuenta', false);
            if (rateChargePFA) {
                setRateCharge(rateChargePFA);
                setInsurance(true);
                setShowProducts(true);
                setProductCount(countProduct(rateChargePFA));
            }
        } else {
            if (value.target.name === 'estadoDeCuenta' && value.target.checked) {
                formik.setFieldValue('recibosDeNomina', false);
                if (rateChargePFP) {
                    setRateCharge(rateChargePFP);
                    setShowProducts(true);
                    setInsurance(true);
                    setProductCount(countProduct(rateChargePFP));
                }
            } else {
                setRateCharge({
                    credit: {},
                    premiumCredit: {},
                });
                setShowProducts(false);
                setInsurance(false);
            }
        }
        setIsLoading(false);
    };

    const changeTitleSelect = (value: string, formik: FormikProps<FinanceSimulationCalculatorForm>) => {
        setCalculation(calculationInitialValues);
        formik.setFieldValue('product', value);
        formik.setFieldValue('insurance', true);
        setProduct(value);
        typeOfPerson !== 'PM' ? setInsurance(true) : setInsurance(false);
        setCheckedCredit(false);
        setCheckedPremiumCredit(false);
        value === 'Credit' ? setCheckedCredit(true) : setCheckedPremiumCredit(true);
        const arrayMonths = createArrayMonths(value);
        if (arrayMonths.length <= numbers.one) {
            setOpenError(!openError);
        }
        if (riskRate && value !== 'Credit') {
            setFinalPayment(
                ballonPayment *
                    ((riskRate[String(formik.values.months[Number(value)])] as number) / numbers.oneHundred),
            );
        }
        formik.setFieldValue('months', arrayMonths);
        const max = ballonPayment - numbers.oneHundredThousand;
        const maxDownPayment = (max / ballonPayment) * numbers.oneHundred;
        let minDownPayment = numbers.zero;
        let arrayDownPayment = [numbers.zero];
        while (minDownPayment < maxDownPayment) {
            arrayDownPayment.push(minDownPayment);
            minDownPayment = minDownPayment + numbers.five;
        }
        if (value !== 'Credit') {
            const arrayDownPaymentPremium: number[] = [];
            arrayDownPayment.forEach(downPaymentValue => {
                const downPaymentPercentageConvert = (downPaymentValue / numbers.oneHundred) * ballonPayment;
                if (calculateMonths(downPaymentPercentageConvert, arrayMonths, false).length > numbers.zero) {
                    arrayDownPaymentPremium.push(downPaymentValue);
                }
            });
            arrayDownPayment = arrayDownPaymentPremium;
        }
        setTerm(arrayMonths[numbers.one]);
        setDownPaymentPercentage(arrayDownPayment);
        setDownPayment(numbers.zero);
    };

    const validateEndOfTheMonth = (): string => {
        const finalDay = moment().endOf('month');
        const currentDay = moment();
        if (currentDay.add(numbers.five, 'days').isAfter(finalDay)) {
            return finalDay.format('DD/MM/YYYY');
        } else {
            return currentDay.format('DD/MM/YYYY');
        }
    };
    const calculate = async () => {
        try {
            setIsLoading(true);
            let finalPaymentCalculate = numbers.zero;
            let validatedMonths = months;
            const downPaymentCalculate: number =
                (downPaymentPercentage[Math.trunc(Number(downPaymentSelected))] / numbers.oneHundred) * ballonPayment;
            setDownPayment(downPaymentCalculate);
            let rateChargeRequest: termRateCharge = {};
            if (product === 'Credit') {
                rateChargeRequest = rateCharge.credit;
            } else {
                if (riskRate) {
                    validatedMonths = calculateMonths(downPaymentCalculate, monthsInitialValues, true);
                    finalPaymentCalculate =
                        ballonPayment * ((riskRate[String(validatedMonths[term])] as number) / numbers.oneHundred);
                    setFinalPayment(finalPaymentCalculate);
                }
                rateChargeRequest = rateCharge.premiumCredit;
            }
            const body = {
                financialAmount: ballonPayment - downPaymentCalculate,
                term: validatedMonths[term],
                carRate:
                    rateCharge && rateChargeRequest[months[term].toString()].carRate
                        ? rateChargeRequest[months[term].toString()].carRate * numbers.oneHundred
                        : numbers.zero,
                isPremiumCredit: product !== 'Credit',
                finalPayment: product !== 'Credit' ? finalPaymentCalculate : numbers.zero,
                includesService: insurance,
                serviceRate:
                    insurance && rateCharge && rateChargeRequest[months[term].toString()].serviceRate
                        ? rateChargeRequest[months[term].toString()].serviceRate * numbers.oneHundred
                        : numbers.zero,
            };
            await CpDataApi.post(getCalculationEndpoint(), body)
                .then(response => {
                    setCalculation(response.data);
                })
                .catch(_error => {
                    setOpenError(!openError);
                    setCalculation(calculationInitialValues);
                });
            setIsLoading(false);
        } catch (_error) {
            setIsLoading(false);
        }
    };

    const sendEmails = async (formik: FormikProps<FinanceSimulationCalculatorForm>) => {
        setIsLoading(true);
        const responseContact = await CpDataApi.get<ContactData>(getContactDataEndpoint())
            .then(response => response.data)
            .catch();
        const bodyRemarketing = {
            fullName: profile?.name,
            contractNumber: contract?.contractLabel,
            telephoneNumber: responseContact.cellphone ? responseContact.cellphone : '',
            email: responseContact.email ? responseContact.email : auth.isAuthenticated ? auth.tokenParsed.email : '',
            shiftSelected: formik.values.shiftSelected,
            term: months[term].toString(),
            monthlyPayment: insurance
                ? formatAsCurrency(responseCalculation.monthlyPayment + responseCalculation.insuranceMonthlyPayment)
                : formatAsCurrency(responseCalculation.monthlyPayment),
            ballonPayment: formatAsCurrency(finalPayment),
            downPayment: formatAsCurrency(downPayment),
        };
        const bodyClient = {
            term: months[term].toString(),
            monthlyPayment: insurance
                ? formatAsCurrency(responseCalculation.monthlyPayment + responseCalculation.insuranceMonthlyPayment)
                : formatAsCurrency(responseCalculation.monthlyPayment),
            downPayment: formatAsCurrency(downPayment),
            ballonPayment: formatAsCurrency(finalPayment),
            email: responseContact.email ? responseContact.email : auth.isAuthenticated ? auth.tokenParsed.email : '',
            fullName: profile?.name,
        };
        const bodyRequest: FinanceSimulationRequest = {
            customerId: customerId ? customerId.toString() : '',
            name: profile?.name ? profile?.name : '',
            shift: formik.values.shiftSelected,
            contractNumber: contract?.contractLabel ? contract?.contractLabel : '',
            dueDateContract: contract?.contractEndDate ? contract?.contractEndDate : '',
            originalBallonPayment: ballonPayment,
            requestedDate: moment().format('DD/MM/YYYY HH:mm:ss'),
            preAuthorizationAmount: loyaltyContract ? loyaltyContract.preAuthorizedMonthlyPayment : 0,
            product: product,
            term: months[term],
            downPayment: downPayment,
            financedAmount: ballonPayment - downPayment + (insurance ? responseCalculation.cpi : numbers.zero),
            monthlyPayment: insurance
                ? responseCalculation.monthlyPayment + responseCalculation.insuranceMonthlyPayment
                : responseCalculation.monthlyPayment,
            insurance: insurance,
            simulatedBallonPayment: finalPayment,
            email: responseContact.email ? responseContact.email : auth.isAuthenticated ? auth.tokenParsed.email : '',
        };
        await CpDataApi.post(getSendFinanceSimulationMailEndpoint('remarketing'), bodyRemarketing)
            .then(async () => {
                await CpDataApi.post(getSendFinanceSimulationMailEndpoint('customer'), bodyClient)
                    .then(async () => {
                        await CpDataApi.post(getCreateRequestEndpoint(), bodyRequest)
                            .then(response => {
                                if (response.data.message === 'error') {
                                    setIsLoading(false);
                                    setOpenError(!openError);
                                } else {
                                    setIsLoading(false);
                                    setShownModal(true);
                                }
                            })
                            .catch(() => {
                                setIsLoading(false);
                                setOpenError(!openError);
                            });
                    })
                    .catch(() => {
                        setIsLoading(false);
                        setOpenError(!openError);
                    });
            })
            .catch(() => {
                setIsLoading(false);
                setOpenError(!openError);
            });
    };

    useEffect(() => {
        if (product === 'Premium Credit') {
            setMonths(calculateMonths(downPayment, createArrayMonths('Premium Credit'), true));
        }
        setMonths(createArrayMonths(product));
    }, [product]);

    useEffect(() => {
        if (product === 'Premium Credit') {
            setIsLoading(true);
            setMonths(calculateMonths(downPayment, createArrayMonths('Premium Credit'), true));
            setIsLoading(false);
        }
    }, [downPayment]);

    useEffect(() => {
        setTerm(numbers.zero);
    }, [months]);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!isLoadingPage && typeOfPerson === 'PM' && rateChargePM) {
            setRateCharge(rateChargePM);
            setShowProducts(true);
            setInsurance(false);
            if (
                Object.keys(rateChargePM.credit).length === numbers.zero &&
                Object.keys(rateChargePM.premiumCredit).length === numbers.zero
            ) {
                setOpenError(!openError);
            } else {
                setProductCount(countProduct(rateChargePM));
            }
        } else {
            if (
                !isLoadingPage &&
                rateChargePFP &&
                rateChargePFA &&
                Object.keys(rateChargePFP.credit).length === numbers.zero &&
                Object.keys(rateChargePFP.premiumCredit).length === numbers.zero &&
                Object.keys(rateChargePFA.credit).length === numbers.zero &&
                Object.keys(rateChargePFA.premiumCredit).length === numbers.zero
            ) {
                setOpenError(!openError);
            }
        }
    }, [isLoadingPage]);

    useEffect(() => {
        if (term) {
            calculate();
        }
    }, [term, downPaymentSelected, insurance, product]);

    return (
        <>
            <ModalMessage
                shown={shownModal}
                onConfirm={() => {
                    setShownModal(false);
                    backDashboard();
                }}
            />
            <ToastNotifications>
                <ToastNotification error={true} onClick={() => setOpenError(!openError)} {...toastPropsError}>
                    {<>{t('error')}</>}
                </ToastNotification>
            </ToastNotifications>

            {isLoading && <Spinner fullPage={true} />}
            <ContentSection pageWrapSize="medium">
                <Heading level={1}>{t('headline')}</Heading>
                <Heading level={3} className="u-mb-none">
                    {car}
                </Heading>
                <Heading level={3}>{finalAmount}</Heading>
                <Formik
                    enableReinitialize
                    initialValues={{
                        incomeSelected: false,
                        insurance: false,
                        recibosDeNomina: false,
                        estadoDeCuenta: false,
                        representoUnaEmpresa: typeOfPerson === 'PM',
                        product: '',
                        shiftSelected: '',
                        months: [],
                        privacyCheck: false,
                    }}
                    onSubmit={() => {
                        backDashboard();
                    }}
                >
                    {formik => (
                        <>
                            <Layout className="c-notification u-mt-none u-ml-xxsmall u-pr o-content-section">
                                <Layout.Item className="u-mt-none">
                                    <h4>{t('income')}</h4>
                                    <ValidatedCheckboxGroup
                                        label=""
                                        name={'contactOptionsName'}
                                        isMandatory={true}
                                        handleChange={value => {
                                            changeCheckBox(value as EventCheckBox, formik);
                                        }}
                                    >
                                        {renderOptions()}
                                    </ValidatedCheckboxGroup>
                                </Layout.Item>
                            </Layout>
                            <br />
                            {showProducts && (
                                <Layout className="c-notification u-mt-none u-ml-xxsmall u-pr o-content-section">
                                    <Layout.Item className="u-mt-none">
                                        <h4>{t('products.title')}</h4>
                                        <TileSelectGroup
                                            key={'product-options'}
                                            layoutItemClassName={`u-1/${productCount} u-1/1@l u-1/1@s`}
                                            className={'u-text-center'}
                                            testId={'tile-select-group-main'}
                                        >
                                            {Object.keys(rateCharge.credit).length > numbers.zero && (
                                                <TileSelect
                                                    checkboxGroupName="name"
                                                    checked={checkedCredit}
                                                    key={numbers.zero}
                                                    icon={'semantic-car'}
                                                    title={t('products.credit.title')}
                                                    inputType="radio"
                                                    radioGroupName="products-item"
                                                    testId={`test-credit.title`}
                                                    onClick={() =>
                                                        changeTitleSelect(t('products.credit.title'), formik)
                                                    }
                                                >
                                                    <div id="products-options-wrapper">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: t('products.credit.description'),
                                                            }}
                                                        />
                                                    </div>
                                                </TileSelect>
                                            )}
                                            {Object.keys(rateCharge.premiumCredit).length > numbers.zero && (
                                                <TileSelect
                                                    key={numbers.one}
                                                    checked={checkedPremiumCredit}
                                                    icon={'semantic-car-value'}
                                                    title={t('products.premiumCredit.title')}
                                                    inputType="radio"
                                                    radioGroupName="products-item"
                                                    testId={`test-premiumCredit.title`}
                                                    onClick={() =>
                                                        changeTitleSelect(t('products.premiumCredit.title'), formik)
                                                    }
                                                >
                                                    <div id="products-options-wrapper">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: t('products.premiumCredit.description'),
                                                            }}
                                                        />
                                                    </div>
                                                </TileSelect>
                                            )}
                                        </TileSelectGroup>
                                    </Layout.Item>
                                </Layout>
                            )}
                            <br />
                            {formik.values.product !== '' && (
                                <Layout className="c-notification u-mt-none u-ml-xxsmall u-pr o-content-section">
                                    <Layout.Item className="u-mt-none">
                                        {loyaltyContract && (
                                            <Notification
                                                key={'preAuthorization'}
                                                showCloseButton={false}
                                                visible={true}
                                                status={NotificationStatus.success}
                                                headline={t('preAuthorization')}
                                                testId={'testIdDefault'}
                                                className="u-mb-small"
                                            >
                                                {formatAsCurrency(loyaltyContract.preAuthorizedMonthlyPayment)}
                                            </Notification>
                                        )}
                                        {contract?.paymentInstallment &&
                                            responseCalculation.monthlyPayment > contract?.paymentInstallment &&
                                            !loyaltyContract && (
                                                <Notification
                                                    key={'monthlyPaymentNotification'}
                                                    showCloseButton={false}
                                                    visible={true}
                                                    status={NotificationStatus.warning}
                                                    testId={'testIdDefault'}
                                                    className="u-mb-small"
                                                >
                                                    {t('warning')}
                                                </Notification>
                                            )}
                                        <h4>{t('inputs.title')}</h4>
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <label>{t('inputs.downpayment')}</label>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <b>{formatAsCurrency(downPayment)}</b>
                                        </Layout.Item>
                                        <Layout.Item default="1/1" className="u-pl-none@s">
                                            <CustomRangeSlider
                                                className="u-text-muted@s"
                                                range={{
                                                    min: numbers.one,
                                                    max: downPaymentPercentage.length - numbers.one,
                                                }}
                                                step={numbers.one}
                                                pips={{
                                                    mode: 'steps',
                                                    density: -numbers.one,
                                                    format: {
                                                        to: (value: number): string => {
                                                            if (width >= numbers.sevenHundred)
                                                                return `${Math.trunc(downPaymentPercentage[value])}%`;
                                                            return '';
                                                        },
                                                    },
                                                }}
                                                value={numbers.zero}
                                                {...(width < numbers.sevenHundred
                                                    ? {
                                                          tooltips: {
                                                              to: (value: number) => {
                                                                  return `${Math.trunc(downPaymentPercentage[value])}%`;
                                                              },
                                                          },
                                                      }
                                                    : { tooltips: false })}
                                                onChange={value => {
                                                    setDownPaymentSelected(Number(value));
                                                }}
                                            />
                                        </Layout.Item>
                                        <h4>{t('inputs.term')}</h4>
                                        <Layout.Item default="1/1" className="u-pl-none@s">
                                            <CustomRangeSlider
                                                className="u-mb-small"
                                                range={{ min: numbers.one, max: months.length - numbers.one }}
                                                pips={{
                                                    mode: 'steps',
                                                    density: -numbers.one,
                                                    format: {
                                                        to: (value: number): string => {
                                                            return `${months[value]}`;
                                                        },
                                                    },
                                                }}
                                                step={numbers.one}
                                                value={numbers.zero}
                                                disabled={months.length <= numbers.two}
                                                onSet={value => {
                                                    setTerm(Number(value));
                                                }}
                                            />
                                        </Layout.Item>
                                        {typeOfPerson !== 'PM' && (
                                            <ValidatedCheckboxGroup
                                                label=""
                                                name={'contactOptionsName'}
                                                handleChange={() => {
                                                    setInsurance(!formik.values.insurance);
                                                }}
                                            >
                                                <ValidatedCheckboxGroup.Checkbox
                                                    name={'insurance'}
                                                    testId={'insurance'}
                                                    label={t('inputs.insurance')}
                                                    groupName={'insurance'}
                                                />
                                            </ValidatedCheckboxGroup>
                                        )}
                                    </Layout.Item>
                                </Layout>
                            )}
                            <br />
                            {responseCalculation.monthlyPayment !== numbers.zero && (
                                <Layout className="c-notification u-mt-none u-ml-xxsmall u-pr o-content-section">
                                    <Layout.Item className="u-mt-none">
                                        <h4>{t('resume.title')}</h4>
                                        <label>{t('resume.subTitle')}</label>
                                        <hr className="u-bg-brand u-mb-none " />
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <label>{t('resume.financingAmount')}</label>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <b>
                                                {formatAsCurrency(
                                                    ballonPayment -
                                                        downPayment +
                                                        (insurance ? responseCalculation.cpi : numbers.zero),
                                                )}
                                            </b>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <label>{t('resume.downpayment')}</label>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <b>{formatAsCurrency(downPayment)}</b>
                                        </Layout.Item>
                                        {formik.values.product !== 'Credit' && (
                                            <>
                                                <Layout.Item default={'1/2'} className={'u-p-none'}>
                                                    <label>{t('resume.balloonPayment')}</label>
                                                </Layout.Item>
                                                <Layout.Item default={'1/2'} className={'u-text-right'}>
                                                    <b>{formatAsCurrency(finalPayment)}</b>
                                                </Layout.Item>
                                            </>
                                        )}
                                        {formik.values.insurance && typeOfPerson !== 'PM' && (
                                            <>
                                                <Layout.Item default={'1/2'} className={'u-p-none'}>
                                                    <label>{t('resume.insurance')}</label>
                                                </Layout.Item>
                                                <Layout.Item default={'1/2'} className={'u-text-right'}>
                                                    <b>
                                                        {formatAsCurrency(responseCalculation.insuranceMonthlyPayment)}
                                                    </b>
                                                </Layout.Item>
                                            </>
                                        )}
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <label>{t('resume.term')}</label>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <b>{`${months[term]} meses`}</b>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <label>{t('resume.product')}</label>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <b>{product}</b>
                                        </Layout.Item>
                                        <hr className="u-bg-brand u-mb-xsmall " />
                                        <Layout.Item default={'1/2'} className={'u-p-none'}>
                                            <h4 className="u-mb-xxsmall">{t('resume.monthlyPayment')}</h4>
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <h4 className="u-mb-xxsmall">
                                                {formatAsCurrency(
                                                    insurance
                                                        ? responseCalculation.insuranceMonthlyPayment +
                                                              responseCalculation.monthlyPayment
                                                        : responseCalculation.monthlyPayment,
                                                )}
                                            </h4>
                                        </Layout.Item>
                                        <h5 className={'u-text-right u-mb'}>
                                            {t('resume.quoteValidation').replace('<date>', validateEndOfTheMonth())}
                                        </h5>
                                        <hr className="u-bg-brand u-mb-none " />
                                        <div
                                            className=" u-mt-xsmall"
                                            dangerouslySetInnerHTML={{
                                                __html: t('resume.cat')
                                                    .replace(
                                                        '<cat>',
                                                        `</Layout.Item><b class="u-text-brand">${responseCalculation.cat}</b>`,
                                                    )
                                                    .replace(
                                                        '<ordinaryRate>',
                                                        `<b class="u-text-brand">${responseCalculation.ordinaryRate}</b>`,
                                                    ),
                                            }}
                                        />
                                    </Layout.Item>
                                </Layout>
                            )}
                            <br />
                            {responseCalculation.monthlyPayment !== numbers.zero && (
                                <Layout className="c-notification u-mt-none u-ml-xxsmall u-pr o-content-section">
                                    <Layout.Item className="u-mt-none">
                                        <h4>{t('timePreferences.title')}</h4>
                                        <label>{t('timePreferences.subTitle')}</label>
                                        <TimePicker
                                            locale={'es'}
                                            name={'shiftSelected'}
                                            flatpickrOptions={{ disableMobile: true }}
                                            maxTime={t('timePreferences.maxTime').toString()}
                                            minTime={t('timePreferences.minTime').toString()}
                                            testId={'shiftSelected'}
                                            onBlur={() => {
                                                if (formik.values.shiftSelected === '') {
                                                    formik.setFieldValue('shiftSelected', '12:00');
                                                }
                                            }}
                                            onChange={(value: string | React.FormEvent<HTMLElement>) => {
                                                if (formik.values.shiftSelected === '') {
                                                    formik.setFieldValue('shiftSelected', '12:00');
                                                } else {
                                                    formik.setFieldValue('shiftSelected', value);
                                                }
                                            }}
                                        />
                                    </Layout.Item>
                                </Layout>
                            )}
                            <label className="u-mt-small">{t('contact.text')}</label>
                            <Button
                                className="u-font-size-fs0 u-mb-xxsmall u-ml-xxsmall"
                                style={{ textTransform: 'none' }}
                                link={true}
                                onClick={() => window.open(myProfilePagePath(), '_blank')}
                            >
                                {t('contact.linkButton')}
                            </Button>
                            {formik.values.shiftSelected && (
                                <Layout className="u-mt-none u-ml-xxsmall u-pr o-content-section u-pr-none">
                                    <Layout.Item className="u-mt-none u-pl-none">
                                        <Layout.Item default={'1/2'} className={'u-p-none  u-pl-none'}>
                                            <ValidatedCheckbox
                                                name={'privacyCheck'}
                                                label={
                                                    <>
                                                        {t('privacyCheck.text')}
                                                        <a
                                                            href={t('privacyCheck.externalUrl')}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {t('privacyCheck.linkButton')}
                                                        </a>
                                                    </>
                                                }
                                                isMandatory={true}
                                            />
                                        </Layout.Item>
                                        <Layout.Item default={'1/2'} className={'u-text-right'}>
                                            <Button
                                                disabled={!formik.values.privacyCheck}
                                                className="o-button-container__button"
                                                secondary
                                                onClick={_value => sendEmails(formik)}
                                            >
                                                {t('button')}
                                            </Button>
                                        </Layout.Item>
                                    </Layout.Item>
                                </Layout>
                            )}
                        </>
                    )}
                </Formik>
            </ContentSection>
        </>
    );
};
