import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { LoyaltyNotificationUi } from './ui';
import { useLoyalty } from './useLoyaltyNotification';

const LoyaltyNotificationWithHandlers = withLoadingAndNoConnectionHandler(LoyaltyNotificationUi);

export const LoyaltyNotification: React.FC = () => {
    const { data, isLoading } = useLoyalty();
    return <LoyaltyNotificationWithHandlers isLoading={isLoading} loyalties={data} hasError={false} />;
};
