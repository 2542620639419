import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';

export type NoConnectionNotificationProps = {
    testId?: string;
    component?: string;
};

export const NoConnectionNotification: React.FC<NoConnectionNotificationProps> = ({ testId, component }) => {
    const { t } = useTranslation(component);
    return (
        <Notification
            status={NotificationStatus.error}
            headline={t('error.noConnection.title')}
            text={t('error.noConnection.text')}
            testId={testId}
        />
    );
};
