import React from 'react';
import { withNoConnectionHandler } from '../integration-wrapper';
import { RenewalOptionsUi } from './ui';
import { useRenewalCreditOptions, useRenewalLeasingOptions, useRenewalPremiumCreditOptions } from './useRenewalOptions';
import { useRenewalContract } from '../renewal-notification/useRenewalOption';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { useFinanceSimulationModelContent } from '../finance-simulation-calculator/useFinanceSimualtion';

export const RenewalOptions: React.FC = () => {
    const RenewalOptionsWithHandlers = withNoConnectionHandler(RenewalOptionsUi);
    let content;
    const { cmsContent: credit } = useRenewalCreditOptions();
    const { cmsContent: leasing } = useRenewalLeasingOptions();
    useFinanceSimulationModelContent();
    const { cmsContent: premiumCredit } = useRenewalPremiumCreditOptions();
    useAnalyticsPageViewTracker('digitalRenewalPage', !!RenewalOptions);

    const { renewalContractState } = useRenewalContract();
    switch (renewalContractState.financeProduct.trim()) {
        case 'Credit':
            content = credit;
            break;
        case 'Leasing':
            content = leasing;
            break;
        case 'Premium Credit':
            content = premiumCredit;
            break;
        default:
            content = [];
    }

    return (
        <RenewalOptionsWithHandlers
            renewalOptions={content}
            renewalContractSelected={renewalContractState}
            hasError={false}
        />
    );
};
