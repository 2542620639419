import { Faq, getFaqEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Faq>({
    contentName: 'faq',
    contentEndpoint: getFaqEndpoint,
});

export default reducer;
export const fetchFaq = fetchContent;
