/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

import { CategorySelectionUI } from './UI/CategorySelectionUI';
import { useCategorySelection } from './useCategorySelection';

const CategorySelectionWithHandlers = withLoadingAndNoConnectionHandler(CategorySelectionUI);

export const CategorySelection: React.FC<{
    currentCategorySelection?: string;
    setParentId: Function;
    setCategoryType: Function;
    contractProduct: string;
}> = props => {
    const { data: categoryResponse, isLoading, loadingError } = useCategorySelection();
    return (
        <CategorySelectionWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            data={categoryResponse}
            {...props}
        />
    );
};
