import React from 'react';

import { DashboardMarketingCard } from '@cp-mx/common';
import { Card, Button } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export const DashboardMarketingCardUi: React.FC<{
    dashboardMarketingCard?: DashboardMarketingCard;
    content?: string;
}> = ({ dashboardMarketingCard, content }) => {
    const { onAction: onClickMarketingCardAction } = useAnalyticsActionTracker('onClickMarketingCard');

    if (!dashboardMarketingCard) {
        return null;
    }

    const { title, pretitle, imageUrl, buttonUrl, buttonLabel, text } = dashboardMarketingCard[
        content ? content : 'marketing'
    ];

    const newWindowFnOnClick = () => {
        onClickMarketingCardAction(title);
        window.open(buttonUrl, '_blank');
    };
    return (
        <Card
            element="article"
            title={title || ''}
            subtitle={pretitle || ''}
            imageSrc={imageUrl || ''}
            footer={true}
            buttons={[
                <Button
                    key={buttonUrl || 'isLoading'}
                    link={true}
                    href={buttonUrl}
                    icon="semantic-forward"
                    iconReversed
                    small
                    onClick={() => newWindowFnOnClick()}
                >
                    {buttonLabel}
                </Button>,
            ]}
        >
            {text}
        </Card>
    );
};
