import React from 'react';
import moment from 'moment-timezone';
import { withLoadingAndNoConnectionHandler } from '../../integration-wrapper';

import { useLegalFooter } from './useLegalFooter';
import { LegalFooter as LegalFooterUi } from '@cp-shared-9/frontend-ui';
import { useLocation } from 'react-router-dom';
import { financeSimulationPath } from '../../navigation/paths';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();
    const currentPathName = useLocation().pathname;
    const copyrightText = legalFooter?.copyrightText ? `${legalFooter?.copyrightText} ${moment().year()}` : undefined;
    let footnotes: Array<string> = [];
    let legalText: string | React.ReactNode = legalFooter?.legalText;
    if (currentPathName === financeSimulationPath()) {
        legalText = (
            <>
                <p>{legalFooter?.legalText}</p>
                <p>{legalFooter?.calculatorLegalText}</p>
            </>
        );
        footnotes = legalFooter?.footnotes ? legalFooter?.footnotes : footnotes;
    }
    return (
        <LegalFooterWithHandlers
            copyrightText={copyrightText}
            legalText={legalText}
            isLoading={isLoading}
            links={legalFooter?.links || []}
            hasError={!!loadingError}
            footnotes={footnotes}
        />
    );
};
