import { HeroImage as FaqTeaserUi } from '@cp-shared-9/frontend-ui';

import { withNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import { useFaqTeaser } from './useFaqPageTeaser';

export const FaqTeaser: React.FC = () => {
    const { cmsContent: FaqTeaser, loadingError } = useFaqTeaser();
    const FaqTeaserWithHandlers = withNoConnectionHandler(FaqTeaserUi);

    return (
        <FaqTeaserWithHandlers
            hasError={!!loadingError}
            title={FaqTeaser?.title || ''}
            imageUrl={FaqTeaser?.imageUrl || ''}
            subTitle={FaqTeaser?.subTitle}
            inverted={!!FaqTeaser?.isInverted}
            shallow={true}
        />
    );
};
