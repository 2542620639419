import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RegistrationError, RegistrationResult } from '@cp-mx/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { CleaveInput, preventSubmit, ValidatedCheckbox } from '@cp-shared-9/frontend-ui';
import { businessIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { BusinessFormProps } from '../types';
import { legalNoticePath, privacyPolicyPath } from '../../../../navigation/paths';

type BusinessIdentificationFormProps = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: BusinessFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const BusinessIdentificationForm: React.FC<BusinessIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const translationPrefix = 'registration-form.business.input-labels';

    const onSubmit = (formValues: BusinessFormProps): void => {
        onSubmitClick();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        businessCustomerNumber: {
            text: t(`${t(`${translationPrefix}.customer-number.label`)} `),
            tooltip: t(`${translationPrefix}.customer-number.tooltip`),
        },
        dateFounded: {
            text: t(`${t(`${translationPrefix}.date-founded.label`)} `),
            tooltip: t(`${translationPrefix}.date-founded.tooltip`),
        },
        confirmTermsAndConditions: {
            text: t(`${translationPrefix}.agreements.terms-and-conditions.label`),
            link: t(`${translationPrefix}.agreements.terms-and-conditions.link`),
            tooltip: t(`${translationPrefix}.agreements.terms-and-conditions.tooltip`),
        },
        confirmPrivacyPolicy: {
            text: t(`${translationPrefix}.agreements.privacy-policy.label`),
            link: t(`${translationPrefix}.agreements.privacy-policy.link`),
            tooltip: t(`${translationPrefix}.agreements.privacy-policy.tooltip`),
        },
    };

    return (
        <Layout.Item>
            <Formik
                initialValues={{
                    businessCustomerNumber: '',
                    dateFounded: '',
                    confirmTermsAndConditions: false,
                    confirmPrivacyPolicy: false,
                }}
                validationSchema={businessIdentificationFormValidationSchema(t)}
                onSubmit={onSubmit}
            >
                {formik => (
                    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => preventSubmit(e)}>
                        <Fieldset>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.businessCustomerNumber.text}
                                    tooltip={labels.businessCustomerNumber.tooltip}
                                    name={'businessCustomerNumber'}
                                    testId={'business-customer-number'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '/',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.dateFounded.text}
                                    tooltip={labels.dateFounded.tooltip}
                                    name={'dateFounded'}
                                    testId={'date-founded'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {`${labels.confirmTermsAndConditions.text} `}
                                            <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmTermsAndConditions.link}
                                            </a>
                                        </>
                                    }
                                    name="confirmTermsAndConditions"
                                    testId={'business-confirm-terms-and-conditions'}
                                />
                            </Fieldset.Row>

                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {`${labels.confirmPrivacyPolicy.text} `}
                                            <a href={privacyPolicyPath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmPrivacyPolicy.link}
                                            </a>
                                        </>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={'business-confirm-privacy-policy'}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                    testId={'submit-business-registration-button'}
                                >
                                    {t(`registration-form.button-labels.confirm`)}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Layout.Item>
    );
};
