import React, { useState } from 'react';
import {
    ButtonContainer,
    Button,
    ContentSection,
    Heading,
    Modal,
    PageWrap,
    TileSelect,
    TileSelectGroup,
    Breadcrumb,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    getRenewalChoiceEndpoint,
    RenewalChoice,
    RenewalOption,
    RenewalContract,
    NotificationsCenter,
    getNotificationsByCustomerEndpoint,
    updateRenewalCloseEndpoint,
    postSendEmailRenewalChoiceEndpoint,
    NotificationCenter,
    getNotificationsCenterEndpoint,
    RenewalPremiumCreditInfoModalStep,
} from '@cp-mx/common';
import { useHistory } from 'react-router-dom';
import { useUpdateNotificationCenter } from '../../notification-center/useUpdateNotificationCenter';
import { useBrandContent } from '../../contracts';
import { CpDataApi } from '../../../cp-xhr';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { CtxNotification } from '../../notifications/context-notification/ContextNotification';
import { renewalFlowState } from '../../renewal-notification/ui';
import { useUpdateNotificationCenterComponent } from '../../notification-center/useUpdateNotificationCenterComponent';
import { useRenewalNotificationClose } from '../../renewal-notification/useNotificationClose';
import { useRenewalPremiumCreditInfoModalSteps } from '../useRenewalOptions';
import { dashboardPagePath, financeSimulationPath } from '../../navigation/paths';

const ModalMessage: React.FC<{
    shown: boolean;
    onConfirm: () => void;
}> = ({ shown, onConfirm }) => {
    const { t } = useTranslation('renewal-modal');

    return (
        <Modal
            hideCloseButton
            shown={shown}
            title={t('title')}
            onConfirm={() => onConfirm()}
            buttonConfirmText={t('confirm')}
            testId={'confirm-modal'}
        >
            {t('body')}
        </Modal>
    );
};

/**
 * Finance explanation modal
 * @param param modal properties
 * @returns {JSX.Element} Finance explanation modal
 */
const FinanceExplanationModal: React.FC<{
    steps: Array<RenewalPremiumCreditInfoModalStep>;
    shown: boolean;
    setShownFinanceExplanationModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ steps, shown, setShownFinanceExplanationModal }): JSX.Element => {
    const { t } = useTranslation('renewal-premium-credit-info-modal');
    const financeStepBreadCrumbOption = steps.map(({ id, iconClass, description }) => (
        <li className="c-breadcrumb__item o-layout__item u-1/6 u-1/1@s u-2/6@m u-m-none u-pl-none" key={id} id={id}>
            <div
                id="finance-explanation-modal__breadcrumb_option"
                className="u-text-center u-ml-none@s"
                style={{ width: '100%' }}
            >
                <i id="finance-explanation-modal__breadcrumb_option_icon" className={iconClass}></i>
                <p id="finance-explanation-modal__breadcrumb_option_description">{description}</p>
            </div>
        </li>
    ));

    return (
        <Modal
            center={true}
            large={true}
            shown={shown}
            title={t('title')}
            onClose={() => setShownFinanceExplanationModal(false)}
            testId={'finance-explanation-modal'}
        >
            <Breadcrumb id="finance-explanation-modal__breadcrumb" className="u-mt">
                {financeStepBreadCrumbOption}
            </Breadcrumb>
        </Modal>
    );
};

/**
 * More info component for financial products
 * @param param renewal option props
 * @returns {JSX.Element} Info element
 */
const MoreInfo: React.FC<{
    option: RenewalOption;
    onAction: (option: RenewalOption) => void;
}> = ({ option, onAction }): JSX.Element => {
    if (option.moreInfo === undefined) {
        return (
            <div id="more-info-default">
                <br />
                <br />
                <br />
            </div>
        );
    } else if (option.id === 'premium-credit-refinance') {
        return (
            <div id="premium-credit-more-info-content">
                <i
                    id="premium-credit-more-info-content__icon"
                    onClick={() => onAction(option)}
                    className={option.moreInfo.iconClass}
                ></i>
                <b
                    id="premium-credit-more-info-content__text"
                    style={{ display: 'inline' }}
                    className="c-tile-select__title u-ml-xxsmall u-mp-none u-font-size-fs1"
                    onClick={() => onAction(option)}
                >
                    {option.moreInfo.text}
                </b>
            </div>
        );
    }

    return (
        <div id="more-info-default">
            <br />
            <br />
            <br />
        </div>
    );
};

export const RenewalOptionsUi: React.FC<{
    renewalOptions?: RenewalOption[];
    renewalContractSelected: RenewalContract;
}> = ({ renewalOptions, renewalContractSelected }) => {
    const { t } = useTranslation('renewal-options');

    const history = useHistory();

    const { updateNotificationCenter } = useUpdateNotificationCenter();

    const { updateNotificationCenterComponent } = useUpdateNotificationCenterComponent();

    const { deleteNotification } = useRenewalNotificationClose();

    const { cmsContent: brandCatalog } = useBrandContent();

    const backDashboard = () => history.push(dashboardPagePath());

    const [shownModal, setShownModal] = useState(false);
    const [shownFinanceExplanationModal, setShownFinanceExplanationModal] = useState(false);
    const [shownSpinner, setSpinner] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [disableButton, setDisableButton] = useState(true);

    const [selection, setSelection] = useState({} as RenewalChoice);
    const { onAction: onClickRenewalCard } = useAnalyticsActionTracker('digitalRenewal');

    const { cmsContent: premiumCreditInfoModalSteps } = useRenewalPremiumCreditInfoModalSteps();
    const handleClick = () => backDashboard();
    const handleOnConfirm = async () => {
        if (selection.optionId === 'premium-credit-refinance') {
            history.push(financeSimulationPath());
        } else {
            setSpinner(true);
            try {
                const sendEmailPayload = {
                    sendTo: selection.emailReceipt,
                    contractId: selection.contractLabel,
                    product: selection.financeProduct,
                    optionTitle: selection.optionTitle,
                };

                if (
                    selection.optionId !== 'credit-finish-contract' &&
                    selection.optionId !== 'premium-credit-pay-off'
                ) {
                    await CpDataApi.post<string>(postSendEmailRenewalChoiceEndpoint(), sendEmailPayload);
                }

                await CpDataApi.post(getRenewalChoiceEndpoint(), selection);

                await CpDataApi.patch(updateRenewalCloseEndpoint(), {
                    contractNumber: renewalContractSelected.contractNumber,
                    flowState: renewalFlowState.OptionSelected,
                });
                const newDataNotification = await CpDataApi.get<NotificationsCenter[]>(
                    getNotificationsByCustomerEndpoint(),
                ).then(response => response.data);
                updateNotificationCenter(newDataNotification);
                const newDataNotificationCenter = await CpDataApi.get<NotificationCenter[]>(
                    getNotificationsCenterEndpoint(),
                ).then(response => response.data);
                await updateNotificationCenterComponent(newDataNotificationCenter);
                deleteNotification(renewalContractSelected.contractNumber);
                setSpinner(false);
                setShownModal(true);
            } catch (e) {
                console.log(e);
                setHasError(true);
                setSpinner(false);
            }
        }
    };

    const brandName =
        brandCatalog && brandCatalog[renewalContractSelected.brand]
            ? brandCatalog[renewalContractSelected.brand]
            : renewalContractSelected.brand;

    const replaceTextHeadLine = () => {
        return t('headline').replace('<brand>', brandName);
    };

    const onHandleClickOption = (option: RenewalOption) => {
        const date = moment().format('DD/MM/YYYY');
        const data = {
            emailReceipt: option.email,
            optionTitle: option.title,
            optionId: option.id,
            ...renewalContractSelected,
            date,
            brand: brandName,
        };
        setSelection(data);
        setDisableButton(false);
    };

    const onHandleClickInfoOption = (option: RenewalOption) => {
        if (option.id === 'premium-credit-refinance') {
            setShownFinanceExplanationModal(true);
        }
    };

    if (!renewalOptions?.length) {
        return null;
    }

    return (
        <PageWrap>
            <ContentSection pageWrapSize="medium">
                <Heading level={1} className="u-mb-large">
                    {replaceTextHeadLine()}
                </Heading>
                <h3>{t('subHeadline')}</h3>
                <p>{t('body')}</p>
                <TileSelectGroup
                    key={'group-renewal-options'}
                    layoutItemClassName="u-1/3 u-1/2@l u-1/1@s"
                    className={'u-text-center'}
                    testId={'tile-select-group-main'}
                >
                    {renewalOptions.map((option, index) => {
                        return (
                            <TileSelect
                                key={index}
                                icon={option.icon}
                                title={option.title}
                                inputType="radio"
                                radioGroupName="renewal-item"
                                onClick={() => onHandleClickOption(option)}
                            >
                                <div id="renewal-options-wrapper">
                                    <div dangerouslySetInnerHTML={{ __html: option.description }} />
                                    <MoreInfo option={option} onAction={onHandleClickInfoOption} />
                                </div>
                            </TileSelect>
                        );
                    })}
                </TileSelectGroup>
            </ContentSection>
            <ButtonContainer center>
                <Button secondary testId={'dashboard-button'} onClick={() => handleClick()}>
                    {t('buttons.back')}
                </Button>
                <Button
                    testId={'submit-button'}
                    type="submit"
                    disabled={disableButton}
                    onClick={() => {
                        onClickRenewalCard(selection.optionTitle);
                        handleOnConfirm();
                    }}
                >
                    {t('buttons.submit')}
                </Button>
            </ButtonContainer>
            {hasError && (
                <ContentSection>
                    <CtxNotification
                        testId="renewal-error"
                        component="renewal-options"
                        visible={hasError}
                        onCloseClick={() => setHasError(false)}
                    />
                </ContentSection>
            )}
            {shownSpinner && <Spinner fullPage />}
            <ModalMessage
                shown={shownModal}
                onConfirm={() => {
                    setShownModal(false);
                    backDashboard();
                }}
            />
            {premiumCreditInfoModalSteps && premiumCreditInfoModalSteps.length && (
                <FinanceExplanationModal
                    steps={premiumCreditInfoModalSteps}
                    shown={shownFinanceExplanationModal}
                    setShownFinanceExplanationModal={setShownFinanceExplanationModal}
                />
            )}
        </PageWrap>
    );
};
