import { createGetContractBasedDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { RateCharge } from '@cp-mx/common';

const { reducer: RateChargePFAReducer, fetchData: fetchDataPFA } = createGetContractBasedDataFetchSlice<
    RateCharge,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'rateChargePFA',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default RateChargePFAReducer;
export const fetchRateChargePFA = fetchDataPFA;
