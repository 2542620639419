import React from 'react';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { ContractUi } from './ui';
import { useContract } from './useContract';

const ContractWithHandlers = withLoadingAndNoConnectionHandler(ContractUi);

export const Contract: React.FC = () => {
    const { data: contract, isLoading, loadingError } = useContract();
    return (
        <ContractWithHandlers
            isLoading={isLoading}
            contracts={contract}
            hasError={!!loadingError}
            component={'contracts'}
        />
    );
};
