import { getFinanceSimulationModelCatalogEndpoint, Model } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Model>({
    contentName: 'finance-simulation-model-catalog',
    contentEndpoint: getFinanceSimulationModelCatalogEndpoint,
});

export default reducer;
export const fetchFinanceSimulationModelCatalog = fetchContent;
