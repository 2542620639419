import { getProductCatalogEndpoint, ProductCatalog } from '@cp-mx/common';
import { createCmsContentSlice } from '../../cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ProductCatalog>({
    contentName: 'prduct-catalog',
    contentEndpoint: getProductCatalogEndpoint,
});

export default reducer;
export const fetchProductCatalog = fetchContent;
