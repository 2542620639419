import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation } from '@cp-shared-9/frontend-ui';
import {
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    myProfilePagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    registrationPagePath,
    privacyPolicyPath,
    cookiePolicyPath,
    legalNoticePath,
    unpaidDetailsPath,
    unauthorizedPagePath,
    openRequestPath,
    renewalPath,
    financeSimulationPath,
} from './components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import { DashboardPage, MasterPage, RegistrationPage } from './pages';
import React, { Suspense } from 'react';
import { history } from './store/rootReducer';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticatedRoute, AuthorizedRoute, AxiosConfiguration } from './auth';
import { FaqPage } from './pages/faq-page';
import { PrivacyPolicyPage } from './pages/privacy-policy-page';
import { CookiePolicyPage } from './pages/cookie-policy-page';
import { LandingPage } from './pages/landing-page';
import { LegalNoticePage } from './pages/legal-notice-page/LegalNoticePage';
import { MyProfilePage } from './pages/my-profile-page';
import { UnpaidBalanceDetailsPage } from './pages/unpaid-balance-details';
import { UnauthorizedPage } from './pages/unauthorized-page';
import { OpenRequestPage } from './pages/open-request-page';
import { SuspensePage } from './pages/suspense-page';
import { RenewalOptionsPage } from './pages/renewal';
import { FinanceSimulationPage } from './pages/finance-simulation-page';

const App: React.FC = () => (
    <div className="App">
        <Suspense fallback={<SuspensePage />}>
            <AuthenticationProvider loadingComponent={<SuspensePage />}>
                <AutoLogout idleTimeInMinutes={180} redirectUri={window.location.origin + landingPagePath()}>
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <LastLocationProvider>
                                <ScrollToTopOnNavigation exceptions={[]} />
                                <MasterPage>
                                    <Switch>
                                        <Route exact path={landingPagePath()}>
                                            <LandingPage />
                                        </Route>
                                        <Route path={privacyPolicyPath()} component={PrivacyPolicyPage} />
                                        <Route path={cookiePolicyPath()} component={CookiePolicyPage} />
                                        <Route path={faqPagePath()} component={FaqPage} />
                                        <Route path={legalNoticePath()} component={LegalNoticePage} />
                                        <Route path={unauthorizedPagePath()} component={UnauthorizedPage} />
                                        <AuthorizedRoute path={dashboardPagePath()}>
                                            <DashboardPage />
                                        </AuthorizedRoute>
                                        <AuthorizedRoute path={myProfilePagePath()}>
                                            <MyProfilePage />
                                        </AuthorizedRoute>
                                        <AuthenticatedRoute path={registrationPagePath()}>
                                            <RegistrationPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={unpaidDetailsPath()}>
                                            <UnpaidBalanceDetailsPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={openRequestPath()}>
                                            <OpenRequestPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={renewalPath()}>
                                            <RenewalOptionsPage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path={financeSimulationPath()}>
                                            <FinanceSimulationPage />
                                        </AuthenticatedRoute>
                                        <Route path={notAuthorizedPagePath()}>
                                            <span>TODO: Not authorized</span>
                                        </Route>
                                        <Route path={notFoundPagePath()}>
                                            <span>TODO: Not found</span>
                                        </Route>
                                        <Route path="*">
                                            <Redirect to={notFoundPagePath()} />
                                        </Route>
                                    </Switch>
                                </MasterPage>
                            </LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AutoLogout>
            </AuthenticationProvider>
        </Suspense>
    </div>
);

export default App;
