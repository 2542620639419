import { updateSessionCounterEndpoint } from '@cp-mx/common';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { CpDataApi } from '../../cp-xhr';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
    NavigationBar as NavigationBarShared,
    SimpleNavItemProps,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-9/frontend-ui';

import {
    cookiePolicyPath,
    privacyPolicyPath,
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    legalNoticePath,
    myProfilePagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    registrationPagePath,
    openRequestPath,
} from './paths';
import { useLogin } from '../../auth/useLogin';
import { useAuthorization } from '../../auth';
import { useNotificationCenter } from '../notification-center/useNotificationCenter';
import { NotificationCenter } from '../notification-center/NotificationCenterComponent';

const pagesWithoutNavbar = [registrationPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    privacyPolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
];

function publicNavigationLinks(t: TFunction, sessionButton: SimpleNavItemProps): SiteNavItemPropsList {
    return [
        {
            url: landingPagePath(),
            label: t('navigation.home'),
            isReversed: true,
        },
        sessionButton,
    ];
}
function privateNavigationLinks(t: TFunction, sessionButton: SimpleNavItemProps): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
            isReversed: true,
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
            isReversed: true,
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
            isReversed: true,
        },
        {
            url: openRequestPath(),
            label: t('navigation.openRequest'),
            isReversed: true,
        },
        sessionButton,
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const location = useLocation();

    useNotificationCenter();

    const { isAuthenticated, logout } = useAuthentication();
    const { isAuthorized } = useAuthorization();
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');
    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');

    const login = useLogin();
    const logoutFn = useCallback(() => logout({ redirectUri: window.location.origin + landingPagePath() }), [logout]);
    const logoutFnAction = () => {
        CpDataApi.patch(updateSessionCounterEndpoint());
        onLogoutAction();
        logoutFn();
    };
    const loginFnAction = () => {
        onLoginAction();
        login();
    };
    const sessionButton: SimpleNavItemProps = {
        label: isAuthenticated ? t('navigation.logout') : t('navigation.login'),
        onClick: isAuthenticated ? logoutFnAction : loginFnAction,
    };

    const navigationItems = useMemo(
        () =>
            (isAuthenticated && isAuthorized
                ? privateNavigationLinks(t, sessionButton)
                : publicNavigationLinks(t, sessionButton)
            ).map(navItem => ({
                ...{ isActive: location.pathname === navItem.url },
                ...navItem,
            })),
        [isAuthenticated, isAuthorized, sessionButton, location.pathname, t],
    );

    const currentPathName = location.pathname;

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };
    const onLogoClick = useCallback(
        () =>
            isAuthenticated && isAuthorized
                ? (() => history.push(dashboardPagePath()))()
                : (() => history.push(landingPagePath()))(),
        [isAuthenticated, isAuthorized, history],
    );

    const notificationsCenter = isAuthenticated && <NotificationCenter />;

    return (
        <NavigationBarShared
            onLogoClick={onLogoClick}
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            navigation={navigation}
            notificationsCenter={notificationsCenter}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
