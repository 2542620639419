import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-9/frontend-ui';
import { NavigationBar } from '../../components/navigation/NavigationBar';
import { LegalFooter } from '../../components/footer/legal-footer';
import { IconFooter } from '../../components/footer/icon-footer';
import { CookieWarning } from '../../components/cookie-warning/CookieWarning';
import { registrationPagePath } from '../../components/navigation/paths';

export type MasterPageProps = {
    isAuthenticated: boolean;
};

const MasterPageComponent: React.FC<MasterPageProps> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();

    return (
        <AnalyticsProvider
            market={'mx'}
            isAuthenticated={isAuthenticated}
            version={'1'}
            releaseDate={'2021-04-12'}
            language={'mx'}
            registrationPath={registrationPagePath()}
            loginPath={'/authentication/callback'}
            brand={'vwfs'}
        >
            <MasterPageShared
                cookieWarning={<CookieWarning />}
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('backToTop')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};

MasterPageComponent.displayName = 'MasterPageComponent';
