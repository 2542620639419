import { createGetContractBasedDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { RateCharge } from '@cp-mx/common';

const { reducer: RateChargePMReducer, fetchData: fetchDataPM } = createGetContractBasedDataFetchSlice<
    RateCharge,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'rateChargePM',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default RateChargePMReducer;
export const fetchRateChargePM = fetchDataPM;
