import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';

export type ErrorNotificationProps = {
    testId: string;
    headline?: string;
    text?: string;
    className?: string;
};

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({ testId, headline, text, className }) => {
    const { t } = useTranslation();

    const headlineText = headline;
    let errorText = text;

    if (!text) {
        errorText = t('error.no-connection.text');
    }

    return (
        <Notification
            status={NotificationStatus.error}
            headline={headlineText}
            text={errorText}
            testId={testId}
            className={className}
        />
    );
};
