import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { NotificationsCenterUI } from './ui/NotificationsCenterUi';
import { useNotificationCenterComponent } from './useNotificationsCenterComponent';
import { useBrandContent } from '../contracts';

const NotificationCenterWithHandlers = withLoadingAndNoConnectionHandler(NotificationsCenterUI);
export const NotificationCenter: React.FC = () => {
    const { data, isLoading } = useNotificationCenterComponent();
    const { cmsContent: brandCatalog } = useBrandContent();
    return (
        <NotificationCenterWithHandlers
            isLoading={isLoading}
            notifications={data}
            hasError={false}
            brandCatalog={brandCatalog}
        />
    );
};
