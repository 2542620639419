import { ContactData, getContactDataEndpoint } from '@cp-mx/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<ContactData, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myContact',
    fetchCallback() {
        return CpDataApi.get<ContactData>(getContactDataEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchMyContact = fetchData;
export const updateContactData = updateData;
