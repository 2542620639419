import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-9/frontend-integration';
import { CatalogOpenRequestResponse } from '@cp-mx/common';
import { fetchCategorySelection } from './CategorySelectionSlice';
import { CategorySelectionContentSelector } from './CategorySelectionContentSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

export function useCategorySelection(): AbstractDataState<
    CatalogOpenRequestResponse[],
    DefaultBusinessMarketApiErrorCode
> {
    return useGetSimpleApiData(fetchCategorySelection, CategorySelectionContentSelector);
}
