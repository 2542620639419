import React from 'react';
import { ContractDetails, Contract } from '@cp-mx/common';
import { FinancialDetails } from '../financial-details';
import { VehicleDetails } from '../vehicle-details';
import { Accordion, AccordionItem } from '@cp-shared-9/frontend-ui';

export const ContractDetailsUi: React.FC<{ contract?: Contract; contractDetails?: ContractDetails }> = ({
    contract,
    contractDetails,
}) => {
    if (!contractDetails || !contract) {
        return null;
    }

    return (
        <Accordion openOneAtTime={false}>
            <div className={'u-mt-small u-mb-small'}></div>
            <AccordionItem title={contractDetails.headlineFinancialDetails}>
                <FinancialDetails contract={contract} />
            </AccordionItem>
            <AccordionItem title={contractDetails.headlineVehicleDetails}>
                <VehicleDetails contract={contract} />
            </AccordionItem>
        </Accordion>
    );
};
