import React, { MouseEventHandler, useEffect, useState } from 'react';
import { CpDataApi } from '../../../cp-xhr';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import {
    Contract,
    NotificationCenter,
    formatAsDate,
    patchNotificationsCenterReadEndpoint,
    getNotificationsCenterEndpoint,
    getCertificateEndpoint,
    Brand,
} from '@cp-mx/common';
import moment, { Moment } from 'moment-timezone';
import { Button, NotificationCenter as NotificationCenterComponent, UnorderedList } from '@vwfs-bronson/bronson-react';

import { useContract } from '../../contracts';
import { useRenewalContract } from '../../renewal-notification/useRenewalOption';
import { renewalPath } from '../../navigation/paths';
import { useUpdateNotificationCenterComponent } from '../useUpdateNotificationCenterComponent';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { STAGE } from '../../../const';

import { useRenewalContract as useRenewalContractData } from '../../renewal-notification/useRenewalContract';

export enum LoyaltyStates {
    USED = 'USED',
    NO_USED = 'NO USED',
}

export enum NotificationType {
    LOYALTY = 'LOYALTY',
    RENEWAL = 'RENEWAL',
    INVOICE = 'INVOICE',
    CFDI = 'CFDI',
}

export type NotificationItem = {
    id: string;
    read: boolean;
    timestampText?: string;
    contractId?: string;
    module?: string | undefined;
    title: string;
    closed?: boolean;
    text?: string;
    /** Children to render inside the NotificationCenterItem body element. */
    children?: React.ReactNode;
    /** Show/hide the item’s close button. */
    close?: boolean;
    /** The close button label. */
    closeLabel?: string;
    /** Datetime string for the item. */
    datetime?: string;
    /** Label for "Mark as read" button. */
    markAsReadLabel?: string;
    /** Label for "Mark as unread" button. */
    markAsUnreadLabel?: string;
    /** Callback for the heading title. */
    onClick?: MouseEventHandler;
    /** Callback when closing the item. */
    onClose?: () => void;
    /** Callback for the showUnreadButton. */
    onMarkReadUnread?: MouseEventHandler;
    /** Whether to show or hide the unread button. */
    showUnreadButton?: boolean;
    /** Label to use for adding status meaning to indicator. */
    statusReadLabel?: string;
    /** Show/hide the {@link timestampText} element. */
    timestamp?: boolean;
    /** Literal expression for the {@link datetime} string. */
    /** An optional title element. Managed from {@link NotificationCenter.notifications}. */
    /** Signalizes if the current item is read or unread. Managed from {@link NotificationCenter.notifications}. */
    unread?: boolean;
};

const getTimeStamp: (date: Moment) => string = date => {
    return date && date.isValid && date.isValid()
        ? moment(date)
              .locale('es')
              .fromNow()
        : '';
};

const patchRead = async (notificationId: string): Promise<AxiosResponse> => {
    return await CpDataApi.patch(patchNotificationsCenterReadEndpoint(notificationId));
};

export const NotificationsCenterUI: React.FC<{ notifications?: NotificationCenter[]; brandCatalog?: Brand }> = ({
    notifications,
    brandCatalog,
}) => {
    const { t } = useTranslation('notification-center');
    const { data: contracts } = useContract();
    const history = useHistory();
    const { setRenewalContract } = useRenewalContract();
    const { updateNotificationCenterComponent } = useUpdateNotificationCenterComponent();
    const [notificationsState, setNotifications] = useState<Array<NotificationItem>>([]);
    const [isLoading, setIsLoading] = useState<number[]>([]);
    const { t: tLoyalty } = useTranslation('loyalty-notification');
    const { t: tRenewal } = useTranslation('renewal-notification');
    const { t: tInvoice } = useTranslation('invoice-notification');
    const { t: tCFDI } = useTranslation('cfdi-notification');
    const { data: renewalContracts } = useRenewalContractData();
    const { onAction: onClickNotification } = useAnalyticsActionTracker('onClickMarketingCard');
    const { onAction: onClickNotificationRenewal } = useAnalyticsActionTracker('onDigitalRenewalStart');
    const { onAction: onApiError } = useAnalyticsActionTracker('onRequestCertificateSubmitAPIError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestCertificateSuccess');

    const fetchNotifications = async () => {
        const newDataNotificationCenter = await CpDataApi.get<NotificationCenter[]>(
            getNotificationsCenterEndpoint(),
        ).then(response => response.data);
        await updateNotificationCenterComponent(newDataNotificationCenter);
    };
    const generateCFDIUrl = (idCustomer: string) => {
        onClickNotification('CFDI');
        window.open(tCFDI('url', { env: STAGE !== 'prod' ? `${STAGE}.` : '', customerId: idCustomer }), '_blank');
    };

    const handleOnClickNotificationRenewal = async (notification: NotificationCenter) => {
        const renewalContractSelected = contracts?.find(
            contract => contract.contractNumber === String(notification.contractNumber),
        );
        if (renewalContractSelected) {
            const renewalVehicleDetails = renewalContracts?.find(
                contract => contract.contractNumber === renewalContractSelected.contractNumber,
            );
            setRenewalContract({
                contractEndDate: renewalContractSelected.contractEndDate,
                contractNumber: renewalContractSelected.contractNumber,
                contractLabel: renewalContractSelected.contractLabel,
                vehicle: renewalContractSelected.vehicle.model,
                brand: renewalContractSelected.vehicle.brand,
                financeProduct:
                    renewalContractSelected.product.name !== ''
                        ? renewalContractSelected.product.name
                        : renewalContractSelected.product.type,
                term: renewalVehicleDetails?.term || '',
                vin: renewalVehicleDetails?.vin || '',
            });
            if (!notification.read) {
                await patchRead(notification.id);
                await fetchNotifications();
            }
            onClickNotificationRenewal();
            history.push(renewalPath());
        }
    };

    const replaceTextRenewal = (contract: Contract, notification: NotificationCenter) => {
        const brandName =
            brandCatalog && brandCatalog[contract.vehicle.brand]
                ? brandCatalog[contract.vehicle.brand]
                : contract.vehicle.brand;
        const contractEndDate = contract.contractEndDate ? formatAsDate(contract.contractEndDate) : '-';
        const content = tRenewal('default.remainder')
            .replace('<endOfContractDate>', contractEndDate)
            .replace('<idContract>', contract.contractLabel)
            .replace('<brand>', brandName);
        return (
            <>
                {content}

                <Button
                    style={{ textTransform: 'lowercase' }}
                    link
                    onClick={() => handleOnClickNotificationRenewal(notification)}
                >
                    aquí.
                </Button>
            </>
        );
    };

    const onClickUrlInvoice = (company: string) => {
        if (company === 'GNP') {
            onClickNotification('GNP');
            window.open('https://' + tInvoice('gnpUrl'), '_blank');
        } else {
            onClickNotification('HDI');
            window.open(tInvoice('hdiUrl'), '_blank');
        }
    };

    const replaceTextInvoice = () => {
        return (
            <>
                <b>{tInvoice('subHeader')}</b>
                <br />
                {tInvoice('textBody')}
                <UnorderedList.Item>{tInvoice('qltChubb')}</UnorderedList.Item>
                <UnorderedList.Item>
                    {tInvoice('gnp')}
                    <a onClick={() => onClickUrlInvoice('GNP')}>{tInvoice('gnpUrl')}</a>
                    {tInvoice('gnpAppName')}
                </UnorderedList.Item>
                <UnorderedList.Item>
                    {tInvoice('hdi')}
                    <a onClick={() => onClickUrlInvoice('HDI')}> aquí</a>
                </UnorderedList.Item>
            </>
        );
    };

    const replaceTextCFDI = (idCustomer: string) => {
        return (
            <>
                <b>{tCFDI('subHeader')}</b>
                <br />
                {tCFDI('textBody')}
                <br />
                {tCFDI('secondTextBody')}
                <a onClick={() => generateCFDIUrl(idCustomer)}> aquí</a>
                {tCFDI('lastTextBody')}
            </>
        );
    };

    const createLoyaltyText = (loyaltyId: number, flowState: string) => {
        if (flowState === LoyaltyStates.USED) {
            return <>{tLoyalty('success.body')}</>;
        }
        const display = displayLoadingSpinner(loyaltyId); // eslint-disable-line @typescript-eslint/no-use-before-define
        return (
            <>
                {tLoyalty('warning.loyalty')}
                {tLoyalty('warning.benefit')}
                <br />
                {display}
            </>
        );
    };

    const notificationsMapper = (): NotificationItem[] => {
        if (notifications && notifications?.length > 0) {
            const map = notifications.map(notification => {
                let content;
                let title = '';
                let specificContract;
                if (contracts) {
                    if (notification.module === NotificationType.LOYALTY) {
                        title =
                            notification.flowState === LoyaltyStates.NO_USED
                                ? tLoyalty('warning.headline')
                                : tLoyalty('success.headline');
                        content = createLoyaltyText(notification.loyaltyProgramId, notification.flowState);
                    } else if (notification.module === NotificationType.RENEWAL) {
                        specificContract = contracts.find(
                            contract => contract.contractNumber === notification.contractNumber,
                        );
                        if (specificContract) {
                            content = replaceTextRenewal(specificContract, notification);
                            title = tRenewal('default.headline');
                        }
                    } else if (notification.module === NotificationType.INVOICE) {
                        content = replaceTextInvoice();
                        title = tInvoice('header');
                    } else if (notification.module === NotificationType.CFDI) {
                        content = replaceTextCFDI(notification.idCustomer);
                        title = tCFDI('header');
                    }
                    return {
                        id: notification.id,
                        text: content,
                        read: notification.read,
                        timestampText: notification.createdAt,
                        contractId: specificContract ? specificContract.contractNumber : '',
                        module: notification.module,
                        title,
                    } as NotificationItem;
                } else {
                    return {
                        id: 'id',
                        title: t('notification.noNotificationsTitle'),
                        text: '',
                        read: true,
                        module: undefined,
                    };
                }
            });
            return map;
        } else {
            return [
                {
                    id: 'id',
                    title: t('notification.noNotificationsTitle'),
                    read: true,
                    text: '',
                    module: undefined,
                },
            ] as NotificationItem[];
        }
    };

    const getFileCertificate = async (loyaltyId: number) => {
        setIsLoading(state => {
            state.push(loyaltyId);
            return state;
        });
        setNotifications(notificationsMapper());
        const response = await CpDataApi.get(getCertificateEndpoint(`${loyaltyId}?pdf=true`));
        if (response.data.signedUrl) {
            const url = response.data.signedUrl;
            window.open(url);
            onSuccess();
        } else {
            onApiError();
        }
        setIsLoading(state => {
            state.splice(state.indexOf(loyaltyId));
            return state;
        });
        setNotifications(notificationsMapper());
    };

    const displayLoadingSpinner = (loyaltyId: number) => {
        const linkText = (
            <>
                {tLoyalty('warning.moreInfo')}

                <Button style={{ textTransform: 'lowercase' }} link onClick={() => getFileCertificate(loyaltyId)}>
                    {tLoyalty('warning.link')}
                </Button>

                {tLoyalty('warning.certificate')}
            </>
        );

        const spinner = <Spinner small center />;
        if (isLoading.includes(loyaltyId)) {
            return spinner;
        } else {
            return linkText;
        }
    };

    useEffect(() => {
        if (contracts) {
            setNotifications(notificationsMapper());
        }
    }, [contracts]);

    const notificationItem = (notification: NotificationItem): JSX.Element => {
        if (notification.module === undefined) {
            return (
                <NotificationCenterComponent.Item timestamp={false} close={false}></NotificationCenterComponent.Item>
            );
        }
        return (
            <NotificationCenterComponent.Item
                markAsUnreadLabel={t('notification.markAsUnreadLabel')}
                markAsReadLabel={t('notification.markAsReadLabel')}
                timestamp={true}
                close={false}
                statusReadLabel={t('notification.toolTip')}
                timestampText={getTimeStamp(formatCpDate(notification.timestampText).toMoment())}
                onMarkReadUnread={() => {
                    patchRead(notification.id);
                }}
            >
                {notification.text}
            </NotificationCenterComponent.Item>
        );
    };

    return (
        <NotificationCenterComponent notifications={notificationsState} closeButtonLabel={t('closeButtonLabel')}>
            {(notification: NotificationItem): JSX.Element => notificationItem(notification)}
        </NotificationCenterComponent>
    );
};
