import {
    HeroImage as LandingPageTeaserUi,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-9/frontend-ui';

import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import { useLogin } from '../../auth/useLogin';
import { useLandingPageTeaser } from './useLandingPageTeaser';

export const LandingPageTeaser: React.FC = () => {
    const { cmsContent: landingPageTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const { isAuthenticated } = useAuthentication();
    const login = useLogin();
    const { onAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', !!landingPageTeaser, isAuthenticated);

    const LandingPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LandingPageTeaserUi);
    const onClick = (): void => {
        onAction();
        login();
    };

    return (
        <LandingPageTeaserWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={landingPageTeaser?.title || ''}
            text={landingPageTeaser?.text || ''}
            imageUrl={landingPageTeaser?.imageUrl || ''}
            subTitle={landingPageTeaser?.subTitle}
            buttonText={landingPageTeaser?.buttonText}
            clickHandler={onClick}
            inverted={!!landingPageTeaser?.isInverted}
        />
    );
};
