import { MktPreferencesResponse, getMktPreferencesEndpoint } from '@cp-mx/common';
import { getSimpleDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    MktPreferencesResponse,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'myMktPreferences',
    fetchCallback() {
        return CpDataApi.get<MktPreferencesResponse>(getMktPreferencesEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchMyMktPreferences = fetchData;
export const updateMyMktPreferences = updateData;
